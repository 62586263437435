/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";

import { RiErrorWarningFill } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import { styled } from "styled-components";
import { Colors } from "../Style/Colours";
import BtnLoader from "../Reuseable/BtnLoader";
import { ModalContext } from "../Context/ModalContextProvider";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import AOS from "aos";
import PrintingModal from "./PrintingModal";
import Bell from "../assets/imgs/bell.png";

function Importance({ setShowImporModal }) {
  const [loading, setLoading] = useState(false);
  const [check, setcheck] = useState(false);
  const [show, setShow] = useState(true);
  const [isDisabled, setisDisabled] = useState(true);
  const [printModal, setPrintModal] = useState(false);

  const handleSpray = () => {
    // window.location?.assign('/payment')
    //  window?.location?.assign("/amount");

    setPrintModal(true);
    setShow(false);

    // setTimeout(() => {
    //   window.location?.assign("/payment");
    // }, 10000);
  };

  useEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-in-out",
    });
  }, []);

  useEffect(() => {
    if (check) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }, [isDisabled, check]);
  return (
    <>
      {show && (
        <StyledModalBackground>
          <StyledModalContent
            // maxwidth="80%"
            width="80%"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <Container>
              <div className="btn1">
                <Button
                  fontsize1="1.3rem"
                  onClick={() => {
                    setShowImporModal(false);
                  }}
                >
                  <GrClose />
                </Button>
              </div>

              <aside>
                <div className="icon">
                  {/* <RiErrorWarningFill /> */}
                  <img src={Bell} alt="" />
                </div>

                <div className="important">Before you proceed..</div>
                <div className="text">
                  Please note that the money purchased can only be sprayed at
                  this event.
                </div>

                <div className="check">
                  <div className="iconn" onClick={() => setcheck(!check)}>
                    {check ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                  </div>
                  <div className="agree">
                    I agree to the terms and conditions for buying new notes and
                    spraying.
                  </div>
                </div>

                <Button
                  color="white"
                  backgroundcolor={isDisabled ? "#ffe1cc" : Colors.PrimaryColor}
                  disabled={isDisabled}
                  padding=".6rem"
                  width="100%"
                  type="submit"
                  fontsize1="1rem"
                  radius="2rem"
                  shadow={`3px 0px 5px${Colors.BoxShadow}`}
                  // disabled={isSubmitting}

                  onClick={handleSpray}
                  style={{ margin: "3rem 0", fontWeight: "500" }}
                >
                  {loading ? <BtnLoader /> : "Buy New Notes"}
                </Button>
              </aside>
            </Container>
          </StyledModalContent>
        </StyledModalBackground>
      )}

      {printModal && <PrintingModal setPrintModal={setPrintModal} />}
    </>
  );
}

export default Importance;

const Container = styled.section`
  padding: 0 1rem;
  .btn1 {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.5rem;
  }

  .iconn {
    color: ${Colors?.PrimaryColor};
    font-size: 1.2rem;
    cursor: pointer;
  }

  .check {
    display: flex;
    padding-top: 2rem;
  }
  .agree {
    font-size: 0.8rem;
    font-family: regugarFont;
    padding-left: 0.5rem;
    color: #626060;
  }

  .icon {
    font-size: 6rem;
    color: #ffa300;
  }

  aside {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .important {
    color: #212121;
    font-size: 21px;
    /* font-style: normal; */
    /* font-weight: 500; */
    /* line-height: normal; */
    margin-bottom: 1rem;
  }

  .text {
    color: #626060;
    text-align: center;
    font-size: 0.8rem;
    font-family: regugarFont;
  }
`;
