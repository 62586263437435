import React, { useContext, useEffect, useState } from "react";

import rank from "../assets/live/ranking.svg";
import styled from "styled-components";
import {
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";

import avata from "../assets/live/vector.svg";
import top1 from "../assets/live/top1.svg";
import top2 from "../assets/live/top2.svg";
import top3 from "../assets/live/top3.svg";
import { Colors } from "../Style/Colours";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Aos from "aos";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";

function Ranking() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [people, setPeople] = useState([]);

  const getCode = sessionStorage.getItem("code");
  const getDetails = JSON.parse(sessionStorage.getItem("details"));

  const headers = {
    "Content-Type": "application/json",
  };

  const displayName = sessionStorage.getItem("displayName");

  const { setgeneralPopUpCheck } = useContext(OpenFundsModal);

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestData = {
        code: getCode !== null && getCode !== 'null' && getCode !== undefined && getCode !== 'undefined' ? getCode : (getDetails?.code || null),
      };
      

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/leaderdashboard/`,
        requestData,
        headers
      );

      if (response?.data?.status) {
        setPeople(response?.data?.data?.leaderboard);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  // Sorting the array by the 'amount' property in descending order
  const sortedPeople = [...people].sort(
    (a, b) => parseInt(b.amount) - parseInt(a.amount)
  );

  // Get the total window height
  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <Container>
      <div
        className="cancel1"
        onClick={() => {
          setShowModal(true);
          setgeneralPopUpCheck(false);
          fetchData();
        }}
        style={{ boxShadow: "0px 0px 20px #4f4f4f" }}
      >
        <img src={rank} alt="" width="100%" />
      </div>

      {showModal && (
        <StyledModalBackground1
          height={`cal(100vh - ${headerHeight}px)`}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowModal(false);
              setgeneralPopUpCheck(true);
            }
          }}
        >
          <StyledModalContent
            padding="1rem 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <div className="header">
              <div
                style={{
                  width: "20%",
                  color: Colors.font3,
                  fontSize: "0.8rem",
                }}
              >
                {!loading ? (
                  <>
                    {" "}
                    <span
                      style={{
                        color: "#ff6700",
                        fontSize: "1.2rem",
                        paddingRight: ".3rem",
                      }}
                    >
                      {people && people?.length}
                    </span>
                    Guests
                  </>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "60%", margin: "auto" }}>Leaderboard</div>
              <div
                style={{ width: "20%" }}
                className="Close"
                onClick={() => {
                  setShowModal(false);
                  setgeneralPopUpCheck(true);
                }}
              >
                Close
              </div>
            </div>
            <div style={{ margin: ".9rem .6rem" }}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  {"Loading..."}
                </div>
              ) : (
                sortedPeople &&
                sortedPeople.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        displayName === item.username ? "leaderYou" : `leader`
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "50px", height: "50px" }}>
                          <img
                            src={
                              item.avatar === null || !item?.avatar
                                ? avata
                                : item?.avatar
                            }
                            alt=""
                            className="avata"
                            style={{ objectFit: "contain" }}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div
                          style={{
                            fontWeight: "600",
                            paddingLeft: "1rem",
                          }}
                        >
                          {displayName === item.username ? (
                            <span
                              style={{ color: "white", fontSize: "1.1rem" }}
                            >
                              You
                            </span>
                          ) : (
                            <span style={{ color: Colors.font3, fontFamily: 'regugarFont' }}>
                              {item.username}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d">
                        {index === 0 || index === 1 || index === 2 ? (
                          <div className="gradesImgDiv">
                            <img src={getRankText(index)} alt="" />
                          </div>
                        ) : (
                          <div className="grades">{getRankText(index)}</div>
                        )}
                      </div>
                    </div>
                  );
                })
              )}

              {people.length === 0 && (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "20vh",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "600" }}>
                      Absence of Leaderboard
                    </div>
                    <p
                      style={{
                        fontSize: ".9rem",
                        padding: ".5rem 0",
                        color: "#666666",
                      }}
                    >
                      [ Start spray and rise to the top ]
                    </p>
                  </div>
                </div>
              )}
            </div>
          </StyledModalContent>
        </StyledModalBackground1>
      )}
    </Container>
  );
}

// Function to get the rank text based on the index
function getRankText(index) {
  if (index === 0) {
    return top1;
  } else if (index === 1) {
    return top2;
  } else if (index === 2) {
    return top3;
  } else {
    return `${index + 1}`;
  }
}

export default Ranking;

const Container = styled.div`
  .cancel1 {
    /* background: rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.6rem;
    color: white;
    display: flex;
    width: fit-content;
    border-radius: 50%;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
    cursor: pointer; */

    border-radius: 50%;
    color: white;
    width: 45px;
    background: rgba(256, 256, 256, 0.5);
    box-shadow: 0px 0px 20px #414141;
    padding: 0.25rem 0.4rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 20px #414141;
    }
  }

  .gradesImgDiv {
  }
  .gradesImgDiv img {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 1.6rem 0 0.3rem 0;
  }

  .Close {
    color: #ff6700;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .leader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(235, 233, 233, 0.3);
    background: #fff0e5;
    padding-right: 1rem;
    margin: 0.3rem 0;
  }
  .leaderYou {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(235, 233, 233, 0.3);
    background: #ff6700;
    padding-right: 1rem;
    margin: 0.3rem 0;
  }

  .topimg {
    height: 30px;
    width: 30px;
  }

  .index {
    color: #7f8389;
    font-size: 0.8rem;
    line-height: 10px;
    background: #d7d2ce;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 2px solid #b8b4af;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avata {
    border-radius: 30px;
    border: 2px solid #f4f2f2;
    background: #fff;
    width: 100%;
    box-shadow: 2px 4px 4px 0px rgba(46, 46, 46, 0.36) inset,
      1px 2px 2px 0px rgba(34, 33, 33, 0.22);
  }

  .grades {
    width: 26px;
    height: 26px;
    border: 0.3rem solid #7f8389;
    background-color: #b8b4af;
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #525252;
    font-weight: 600;
  }
`;
