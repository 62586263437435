/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import Navbar from "../Reuseable/Navbar";
import { Colors } from "../Style/Colours";
import { Button } from "../Reuseable/StyledComponents";
import BtnLoader from "../Reuseable/BtnLoader";
import baseURL from "../Request/BaseUrl";
import { ModalContext } from "../Context/ModalContextProvider";
import LandingModal from "../Modals/LandingModal";
import RedirectModal from "../Modals/RedirectModal";
import axios from "axios";
import { motion } from "framer-motion";
import DayFormat from "../Reuseable/DayFormat";
import DateFormet from "../Reuseable/DateFormet";
import TimeFormat from "../Reuseable/TimeFormat";
import CheckCode from "../Modals/CheckCode";
import PageLoader from "../Reuseable/PageLoader";
import { toast } from "sonner";
import { CodeContext } from "../Context/CodeContextProvider1";
import { useFormik } from "formik";
import * as Yup from "yup";

//imgs
import proPics from "../assets/imgs/proPics.svg";
import Celebrate from "../assets/imgs/celebrate1.svg";
import date from "../assets/imgs/calender.svg";
import location from "../assets/imgs/location.svg";

//490676

function LandingPage() {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [checkForCode, setcheckForCode] = useState(false);
  const [data, setData] = useState({
    eventName: "",
    hostName: "",
    hostImg: "",
    eventImg: "",

    about: "",
    locationn: "",
    ceatedAt: "",
    startDate: "",
    startTime: "",
    closeDate: "",
    closeTime: "",
    code: "",
    status: "",
    unique_meeting_name: "",
  });

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);

  document.addEventListener("DOMContentLoaded", () => {
    const resizeMainContent = () => {
      const headerHeight = document.querySelector(".header")?.offsetHeight || 0;
      const mainContent = document.querySelector(".main-content");
      if (mainContent) {
        mainContent.style.height = `calc(100vh - ${headerHeight}px)`;
      }
    };

    // Call resizeMainContent initially to set the correct height
    resizeMainContent();

    // Add event listener for window resize
    window.addEventListener("resize", resizeMainContent);
  });

  const { isopen, setIsopen } = useContext(ModalContext);
  const [reDirect, setReDirect] = useState(false);
  const [storeCode, setStoreCode] = useState("");

  const { code, updateCode, ifClickedOnLeave } = useContext(CodeContext);

  const endpointPost = "/guestapi/onboard/";
  const headers = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const trxref = params.get("code");

    setStoreCode(trxref);

    if (!sessionStorage.getItem("code")) {
      // Set the value of sessionStorage.setItem("code", trxref)
      sessionStorage.setItem("code", trxref);
    }
    if (sessionStorage.getItem("code")) {
      updateCode(sessionStorage.getItem("code"));
    }

    if (trxref) {
      updateCode(trxref);
      params.delete("code");
      const newURL = window.location.pathname + params.toString();
      window.history.replaceState({}, document.title, newURL);
    } else {
      // setcheckForCode(true); //will be uncommented later
    }
  }, []);

  useEffect(() => {
    if (code !== "") {
      fetchData();
    }
  }, [code]);

  const requestData = {
    code: code || sessionStorage.getItem("code"),
    secret: "999999999999999999999",
  };

  const fetchData = async () => {
    setLoading1(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/getpartyinfo/`,
        requestData,
        { headers }
      );

      if (response.data.status) {
        // setcheckForCode(false); //will be uncommented later
        if (
          sessionStorage.getItem("details") &&
          JSON.parse(sessionStorage.getItem("details")).code !== storeCode &&
          loading1
        ) {
          // setData({
          //   eventName: response?.data?.data?.event.name,
          //   eventImg: response?.data?.data?.event.image,
          //   hostName: response?.data?.data?.event.hostname,
          //   hostImg: response?.data?.data?.event.hostimage,
          //   about: response?.data?.data?.event.about,
          //   locationn: response?.data?.data?.event.location,
          //   ceatedAt: response?.data?.data?.event.create_at.slice(0, 10),
          //   startDate: response?.data?.data?.event.startDate,
          //   startTime: response?.data?.data?.event.startTime,
          //   closeDate: response?.data?.data?.event.closedDate,
          //   code: response?.data?.data?.event?.code,
          //   status: response?.data?.data?.event?.status,
          //   unique_meeting_name: response?.data?.data?.event?.unique_meeting_name,
          // });
          // console.log(data, response);
          sessionStorage?.setItem(
            "details",
            JSON.stringify({
              eventName: response?.data?.data?.event.name,
              eventImg: response?.data?.data?.event.image,
              hostName: response?.data?.data?.event.hostname,
              hostImg: response?.data?.data?.event.hostimage,
              about: response?.data?.data?.event.about,
              locationn: response?.data?.data?.event.location,
              ceatedAt: response?.data?.data?.event.create_at.slice(0, 10),
              startDate: response?.data?.data?.event.startDate,
              startTime: response?.data?.data?.event.startTime,
              closeDate: response?.data?.data?.event.closedDate,
              closeTime: response?.data?.data?.event.closedTime,
              code: response?.data?.data?.event?.code,
              status: response?.data?.data?.event?.status,
              unique_meeting_name:
                response?.data?.data?.event?.unique_meeting_name,
            })
          );
          // return;
        }
        if (
          localStorage?.getItem("ok") !== null &&
          response?.data?.data?.event?.status === "Live" &&
          sessionStorage.getItem("roomname") !== null &&
          JSON.parse(sessionStorage.getItem("details"))?.unique_meeting_name !==
            "" &&
          sessionStorage.getItem("displayName") !== null &&
          ifClickedOnLeave
        ) {
          !sessionStorage.getItem("roomname") &&
            sessionStorage?.setItem("roomname", data.unique_meeting_name);
          !sessionStorage.getItem("displayName") &&
            sessionStorage?.setItem("displayName", initialValues.name);
          !sessionStorage.getItem("details") &&
            sessionStorage?.setItem("details", JSON.stringify(data));

          return (
            setLoading1(true),
            toast.success("", {
              description: "Welcome Back",
              style: { color: "green" },
            }),
            setTimeout(() => {
              toast.success("", {
                description: "Your Party is still Going On",
                style: { color: "green" },
              });
            }, 3000),
            setTimeout(() => {
              toast.loading("", {
                description: "Joining...",
                style: { color: "green" },
              });
            }, 6000),
            setTimeout(() => {
              window?.location?.assign("amount");
            }, 9000)
          );
        }
        setData({
          eventName: response?.data?.data?.event.name,
          eventImg: response?.data?.data?.event.image,
          hostName: response?.data?.data?.event.hostname,
          hostImg: response?.data?.data?.event.hostimage,
          about: response?.data?.data?.event.about,
          locationn: response?.data?.data?.event.location,
          ceatedAt: response?.data?.data?.event.create_at.slice(0, 10),
          startDate: response?.data?.data?.event.startDate,
          startTime: response?.data?.data?.event.startTime,
          closeDate: response?.data?.data?.event.closedDate,
          closeTime: response?.data?.data?.event.closedTime,
          code: response?.data?.data?.event?.code,
          status: response?.data?.data?.event?.status,
          unique_meeting_name: response?.data?.data?.event?.unique_meeting_name,
        });
        setLoading1(false);

        setTimeout(() => {
          // setIsopen(true); //will be uncommented later
        }, 3000);
      }
    } catch (error) {
      setLoading1(false);
      // setIsopen(false); //will be uncommented later
      toast.error("", {
        description:
          error?.response?.data?.message ||
          "Opps!! something went wrong, check your network connection and try again",
        style: { color: "red" },
      });

      if (!error?.response?.data?.status) {
        // setReDirect(true); //will be uncommented later
      }
    }
  };

  const initialValues = {
    name:
      sessionStorage.getItem("displayName") !== null ||
      sessionStorage.getItem("displayName") !== undefined
        ? sessionStorage.getItem("displayName")
        : "",
    // phoneNumber:
    //   sessionStorage.getItem("tel") !== null ||
    //   sessionStorage.getItem("tel") !== undefined
    //     ? sessionStorage.getItem("tel")
    //     : "",
    // email: "",
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(
        /^(070|081|080|091|090)[0-9]{8}$/,
        "Incorrect phone number format"
      )
      .required("Phone number is required"),
    // email: Yup.string()
    //   .matches(
    //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //     "Invalid email address"
    //   )
    //   .required("Email is required"),
  });

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      const handleSpray = async () => {
        setLoading(true);

        const requestData = {
          data: {
            // phone: values.phoneNumber,
            name: values.name,
            code: code || sessionStorage.getItem("code"),
            // email: values.email,
          },
        };

        const AddNewAgent = await axios
          .post(`${baseURL}${endpointPost}`, requestData, headers)
          .then((response) => {
            if (response?.data?.status) {
              toast.success("😎 🎉", {
                description:
                  response?.data?.message || "Successfully Procceded",
                style: { color: "green", textAlign: "center" },
              });
              setLoading(false);
              localStorage?.setItem("ok", response?.data?.secret);
              sessionStorage?.setItem("roomname", data.unique_meeting_name);
              sessionStorage?.setItem("displayName", values.name);
              sessionStorage?.setItem("tel", values.phoneNumber);
              sessionStorage?.setItem("details", JSON.stringify(data));
              window?.location?.assign("/amount");
            }
            if (response?.data?.status === false) {
              // setReDirect(true); //will be uncommented later
            }
          })
          .catch((e) => {
            setLoading(false);

            toast.error("", {
              description:
                e?.response?.data?.message ||
                "Opps!! something went wrong, check your network connection and try again",
              style: { color: "red", textAlign: "center" },
            });
          });
      };

      handleSpray();
    },
    validate: (values) => {
      const errors = {};

      // Validate phone number length
      if (values.phoneNumber && values.phoneNumber.length > 11) {
        errors.phoneNumber = "Phone number cannot exceed 11 characters";
      }

      // Add other validation logic here if needed

      return errors;
    },
  });

  return (
    <>
      {loading1 ? (
        <PageLoader />
      ) : (
        <Container>
          <Navbar
            text={
              data?.eventName ? (
                data?.eventName?.toUpperCase()
              ) : (
                <div>Welcome :)</div>
              )
            }
          />
          <section>
            <div className="celeimg" id="celebrateSection">
              <motion.div
                className="image-container"
                initial={{ opacity: 0, y: 20 }}
                animate={isLoaded ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: "easeOut" }}
                style={{ width: "100%", height: "12rem" }}
              >
                <img
                  src={data?.eventImg || Celebrate}
                  alt="celebrate"
                  className="image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={isLoaded ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: "easeOut" }}
                className="proPics"
              >
                <img
                  src={data?.hostImg || proPics}
                  alt="proPics"
                  className="image"
                  height="100%"
                  width="100%"
                  style={{
                    objectFit: "contain",
                    borderRadius: "50%",
                  }}
                />
              </motion.div>
            </div>

            {data?.hostName && (
              <div className="invited">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={isLoaded ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1, ease: "easeOut", delay: 0.4 }}
                >
                  You have been invited to{" "}
                  <strong style={{ color: Colors?.PrimaryColor }}>
                    {data?.eventName && data?.eventName.toUpperCase()}
                  </strong>{" "}
                  by the host{" "}
                  <strong>
                    {data?.hostName && data?.hostName?.toUpperCase()}
                  </strong>
                </motion.div>
              </div>
            )}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={isLoaded ? { opacity: 1, y: 0, x: 0 } : {}}
              transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
            >
              <div className="about" style={{ marginTop: "2rem" }}>
                <div className="hostname">{data?.about && "About Event"}</div>
                <div className="hostoff">{data?.about}</div>
              </div>
            </motion.div>

            {data?.startDate && (
              <div id="invitedSection" style={{ marginTop: "1rem" }}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={isLoaded ? { opacity: 1, y: 0, x: 0 } : {}}
                  transition={{ duration: 0.7, ease: "easeOut", delay: 0.6 }}
                >
                  <div className="date">
                    <div className="datename">Date</div>
                    <div className="host">
                      <div className="hostimg">
                        <img src={date} alt="host" width="100%" />
                      </div>
                      <div>
                        <div className="hostname">
                          <DateFormet value={data?.startDate} /> -{" "}
                          <DateFormet value={data?.closeDate} />
                        </div>
                        <div className="hostoff">
                          {<DayFormat value={data?.startDate} />},{" "}
                          {<TimeFormat value={data?.startTime || "00:00:00"} />}{" "}
                          -{" "}
                          {
                            <TimeFormat
                              value={
                                data?.closeTime
                                  ? data?.closeTime
                                  : data?.closeDate?.slice(11, 19) || "00:00:00"
                              }
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={isLoaded ? { opacity: 1, y: 0, x: 0 } : {}}
                  transition={{ duration: 0.7, ease: "easeOut", delay: 0.8 }}
                >
                  <div className="date">
                    <div className="datename">Location</div>
                    <div className="host">
                      <div className="hostimg">
                        <img src={location} alt="host" width="100%" />
                      </div>
                      <div>
                        <div className="locationname">{data?.locationn}</div>
                        <div className="locationoff">Show in maps</div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            )}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={isLoaded ? { opacity: 1, y: 0, x: 0 } : {}}
              transition={{ duration: 0.7, ease: "easeOut", delay: 1 }}
            >
              <div id="formSection" style={{ marginTop: "1rem" }}>
                <div className="hostoff1">
                  Let’s get your personal information before you proceed
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <label htmlFor="name">Nickname</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="enter your nickname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="error">{formik.errors.name}</div>
                    )}
                  </div>

                  {/* <div style={{ margin: "1rem 0" }}>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="tel" // Use type "tel" for phone numbers
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="e.g 08100000000"
                      onChange={(e) => {
                        if (e.target.value.length <= 11) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      maxLength={11}
                    />
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <div className="error">{formik.errors.phoneNumber}</div>
                      )}
                  </div> */}

                  {/* <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="e.g youremail@gmail.com"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div> */}

                  <Button
                    color="white"
                    className="btnnn"
                    backgroundcolor={
                      formik.values.name ? Colors.PrimaryColor : Colors?.font1
                    }
                    // backgroundcolor={
                    //   formik.values.name && formik.values.phoneNumber
                    //     ? Colors.PrimaryColor
                    //     : Colors?.font1
                    // }
                    padding=".8rem"
                    width="100%"
                    type="submit"
                    fontsize1="1rem"
                    radius="2rem"
                    disabled={!formik.values.name}
                    // disabled={!formik.values.name && !formik.values.phoneNumber}
                    shadow={`3px 0px 5px${Colors.BoxShadow}`}
                    style={{ marginTop: "2rem", fontWeight: "500" }}
                  >
                    {loading ? <BtnLoader /> : "Continue"}
                  </Button>
                </form>
              </div>
            </motion.div>
          </section>

          {isopen && <LandingModal />}
          {reDirect && <RedirectModal setReDirect={setReDirect} />}
          {checkForCode && <CheckCode fetchData={fetchData} />}
        </Container>
      )}
    </>
  );
}

export default LandingPage;

const Container = styled.div`
  section {
    padding: 1rem 1rem 3rem 1rem;
  }

  .proPics {
    width: 85px;
    height: 85px;
    background: white;
    padding: 0.5rem;
    border-radius: 50%;
    position: absolute;
    bottom: -18%;
    box-shadow: -0px 3px 10px #ccc;
  }

  .celeimg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .invited {
    margin-top: 3rem;
    font-size: 1.05rem;
    color: #626060;
  }
  strong {
    font-family: boldFont;
    color: #212121;
  }

  .hostoff {
    font-size: 0.9rem;
    color: ${Colors.font3};
  }
  .hostoff1 {
    font-size: 0.85rem;
    color: ${Colors.font3};
    // padding: .6rem 0;
    margin: 1rem 0;
  }

  .hostimg {
    width: 75px;
    padding-right: 1rem;
  }

  .hostname {
    font-family: MediumFont;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    color: #120d26;
  }

  .host {
    display: flex;
    align-items: center;
    padding: 0.7rem 0;
  }

  input {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
  }

  label {
    font-size: 0.9rem;
    color: #212121;
    font-family: MediumFont;
  }

  .error {
    color: red;
    font-size: 0.8rem;
    transition: linear 0.3s;
  }

  .datename {
    font-family: boldFont;
    font-size: 1rem;
    color: #120d26;
  }

  .locationname {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
  }

  .locationoff {
    font-size: 0.8rem;
    color: #434fbf;
    font-weight: 500;
  }

  @media screen and (min-width: 720px) {
    .hostoff1 {
      text-align: center;
      padding-top: 1.4rem;
      font-size: 1rem;
      font-weight: 600;
    }

    section {
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
`;
