import React from 'react'
import { styled } from 'styled-components'

function Navbar({ text }) {
  return (
    <Container>{text}</Container>
  )
}

export default Navbar

const Container = styled.div`
  text-align: center;
  font-size: 1.3rem;
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  font-weight: 400;
  position: sticky;
  top: 0;
  color: #333;
  background: white;
  z-index: 10;
`