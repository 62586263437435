/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { BsBank, BsFillCreditCardFill, BsLockFill } from "react-icons/bs";
import { FaCcVisa } from "react-icons/fa";
import { TbArrowsTransferUp } from "react-icons/tb";
import { Button } from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import { GrClose } from "react-icons/gr";
import BtnLoader from "../Reuseable/BtnLoader";
import { put } from "../Request/GetAPI";
import baseURL from "../Request/BaseUrl";
import { secret } from "../Request/Secret";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
// import { toast, ToastContainer } from 'react-toastify';
//

function Paystack() {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [getvalue, setgetvalue] = useState("");
  const Value = useRef("");

  const navigate = useNavigate();

  const arr = [
    { name1: "Pay with Card", value: "card", icon: <BsFillCreditCardFill /> },
    { name1: "Pay with Bank", value: "card", icon: <BsBank /> },
    {
      name1: "Pay with Transfer",
      value: "card",
      icon: <TbArrowsTransferUp />,
    },
    { name1: "Pay with Visa QR", value: "card", icon: <FaCcVisa /> },
  ];

  const handleValue = (item) => {
    Value.current = item?.value;
    setgetvalue(Value.current);
  };
  const handlecancel = () => {
    navigate(-1);
  };

  //a1mount2

  const endpoint = "/guestapi/payment/";
  const headers = {
    "Content-Type": "application/json",
  };

  const amount = sessionStorage.getItem("amount");

  const handleSpray = async () => {
    setLoading(true);
    const data = JSON.stringify({
      data: {
        secret,
        transactionType: Value.current,
        amount,
      },
    });

    try {
      const response = await put(baseURL, endpoint, data, headers);

      if (response.status) {
        toast.success("",{
          description: response.message || "Successful",
          style: { color: "green" },
        });

        setLoading(false);
        window?.location?.assign(response?.link);
      } else {
        toast.error("Error", {
          description:
            response.message || "Something went wrong, please try again!",
          style: { color: "red" },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error:", {
        description: "Something went wrong, please try again!",
        style: { color: "red" },
      });
    }
  };

  useEffect(() => {
    setDisabled(Value.current === "");
  }, [getvalue]);
  return (
    <Container>
      <section>
        <div className="payment">PAYMENT CHECKOUT</div>
        <div className="paymenttext">
          Use one of your paymets methods below to buy your new notes
        </div>

        <div>
          {arr?.map((item, i) => {
            return (
              <div>
                <Button
                  radius=".7rem"
                  background1="#34af6c"
                  width="100%"
                  shad="none"
                  align="left"
                  color="#494949"
                  color1="white"
                  padding="1rem .4rem"
                  type="submit"
                  fontsize1=".9rem"
                  style={{ borderTop: `1px solid #e7e7e7 ` }}
                  key={i}
                  onClick={() => handleValue(item)}
                >
                  <div className="icon">{item?.icon}</div>
                  {item?.name1}
                </Button>
              </div>
            );
          })}
        </div>
      </section>

      <div className="cont">
        <Button
          color="#494949"
          backgroundcolor={Colors?.BoxShadow}
          padding=".6rem"
          type="submit"
          fontsize1=".85rem"
          radius=".4rem"
          style={{ fontWeight: "600", border: `1px solid #ccc ` }}
          onClick={handlecancel}
        >
          <div className="icon1">
            {" "}
            <GrClose />
          </div>{" "}
          Cancel Payment
        </Button>

        <div className="securepaystack">
          <div className="lock">
            <BsLockFill />
          </div>
          <div className="secure">Secured by</div>
          <h1 className="paystack">paystack</h1>
        </div>

        <Button
          color="white"
          backgroundcolor={disabled ? "#ffe1cc" : Colors.PrimaryColor}
          padding=".8rem"
          width="100%"
          type="submit"
          fontsize1=".9rem"
          radius="2rem"
          shadow={`3px 0px 5px${Colors.BoxShadow}`}
          disabled={disabled}
          onClick={handleSpray}
          style={{ margin: "7rem 0 0 0", fontWeight: "600" }}
        >
          {loading ? <BtnLoader /> : "Proceed"}
        </Button>
      </div>

      {/* <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </Container>
  );
}

export default Paystack;

const Container = styled.section`
  .payment {
    color: #34af6c;
    font-weight: 600;
    padding: 0.7rem 0;
  }

  .paymenttext {
    color: ${Colors?.font3};
    padding-bottom: 0.7rem;
    font-size: 0.9rem;
  }

  .cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 1rem 1rem 1rem;
  }

  section {
    background: #f4f4f4;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e7e7e7;
  }

  .icon {
    padding-right: 0.8rem;
    font-size: 0.9rem;
  }
  .icon1 {
    color: #767676;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  }

  .securepaystack {
    display: flex;
    align-items: center;
    padding-top: 2.6rem;
  }

  .secure {
    padding: 0 0.4rem;
    font-weight: 400;
    font-size: 0.9rem;
  }
  .paystack {
    font-weight: 700;
    font-size: 1rem;
  }

  @media screen and (min-width: 650px) {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;
