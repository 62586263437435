import React from "react";

function TimeFormat({ value }) {
  const formatTime = (time) => {
    const formattedTime = new Date(`1970-01-01T${time}`).toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  return <>{formatTime(value)}</>;
}

export default TimeFormat;
