import React from "react";
import { styled } from "styled-components";
import { Colors } from "../Style/Colours";
import { Button } from "../Reuseable/StyledComponents";
import failed from "../assets/imgs/failed.gif";
import { useLocation } from "react-router-dom";

function FailedTransaction() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  console.log('location.search:', location.search);

  const paramsValue = queryParams.get("params");
  console.log(paramsValue);
  return (
    <Container>
      <div>
        {" "}
        <img src={failed} alt="" />
      </div>
      <div className="Transaction">Transaction Failed</div>
      <div className="request">
        There might be some issues with your payment option
      </div>

      <Button
        color={Colors?.font1}
        backgroundcolor="white"
        padding=".8rem"
        width="100%"
        type="submit"
        fontsize1="1.2rem"
        radius="2rem"
        border={`2px solid #ccc`}
        shad="none"
      >
        Continue
      </Button>
    </Container>
  );
}

export default FailedTransaction;

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;

  .Transaction {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 0;
  }

  .request {
    font-size: 0.9rem;
    color: ${Colors.font3};
    margin-bottom: 3rem;
    text-align: center;
  }
`;
