import React, { useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import styled from "styled-components";
import BtnLoader from "../Reuseable/BtnLoader";
import { Colors } from "../Style/Colours";

function CheckAmountModal({
  setCheckBalanceModal,
  getBalance,
  loading1,
}) {
  const [loading, setLoading] = useState(false);

  const handleSpray = () => {
    setLoading(true);
    localStorage?.setItem("a1mount2", getBalance);
    window.location?.assign("/live");
  };

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  return (
    <div>
      <StyledModalBackground>
        <StyledModalContent
          // maxwidth="80%"
          width="80%"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          <Container>
            <aside>
              <div className="important">Available Balance</div>
              <div className="text">You have remaining balance of </div>

              <div
                style={{
                  color: Colors.PrimaryColor,
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  margin: ".7rem 0 1.5rem 0",
                  textAlign: "center",
                }}
              >
                ₦ {loading1 ? "loading.." : `${formatNumber(getBalance)}.00`}
              </div>

              <Button
                color="white"
                backgroundcolor={Colors.PrimaryColor}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1="1rem"
                radius="2rem"
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                // disabled={isSubmitting}

                onClick={handleSpray}
                style={{ margin: "1rem 0", fontWeight: "500" }}
              >
                {loading ? <BtnLoader /> : "Start Spraying"}
              </Button>

              <Button
                color={Colors.font4}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                border={`1px solid ${Colors?.font1}`}
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={() => setCheckBalanceModal(false)}
                style={{ fontWeight: "500" }}
              >
                Top Up Balance
              </Button>
            </aside>
          </Container>
        </StyledModalContent>
      </StyledModalBackground>
    </div>
  );
}

export default CheckAmountModal;

const Container = styled.div`
  padding: 1rem;

  .important {
    color: #212121;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }

  .text {
    color: #626060;
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 1rem auto;
  }
`;
