/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import {
  Button,
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import { styled } from "styled-components";
import { ModalContext } from "../Context/ModalContextProvider";
import AOS from "aos";

function LandingModal() {
  const { isopen, setIsopen } = useContext(ModalContext);
  const handleSpray = () => {};

  useEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-in-out",
    });
  }, []);

    // Get the total window height
    const totalHeight = window.outerHeight;

    // Get the viewport height
    const viewportHeight = window.innerHeight;
  
    // Calculate the header height
    const headerHeight = totalHeight - viewportHeight;

  return (
    <>
      <StyledModalBackground1 height={`cal(100vh - ${headerHeight}px)`}>
        <StyledModalContent
          padding="1rem 0 0 0"
          // maxwidth="80%"
          width="100%"
          radius="1rem 1rem 0 0"
          MinHeight="65vh"
          // style={{marginBottom: '1rem'}}

          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          <Container>
            <aside>
              <div className="important">
                Download our app for better experience!
              </div>
              <div className="text">
                Have access to more features with our Partytime app
              </div>

              <Button
                color="white"
                backgroundcolor={Colors.PrimaryColor}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={handleSpray}
                style={{ margin: "3rem 0 .5rem 0", fontWeight: "600" }}
              >
                Download the app
              </Button>
              <Button
                color={Colors.font1}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                border={`1px solid ${Colors?.font1}`}
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={() => setIsopen(false)}
                style={{ fontWeight: "600" }}
              >
                Continue with browser
              </Button>
            </aside>
          </Container>
        </StyledModalContent>
      </StyledModalBackground1>
    </>
  );
}

export default LandingModal;

const Container = styled.section`
  .important {
    color: #212121;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 1rem;
    // text-align: center;
  }

  .text {
    color: #626060;
    // text-align: center;
    font-size: 0.9rem;
  }
`;
