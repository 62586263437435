import React, { useState, useEffect, useContext } from "react";
import { Popover } from "antd";
import { GrClose } from "react-icons/gr";
import { Colors } from "../Style/Colours";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";
import styled from "styled-components";

const PopOver = ({
  open,
  setOpen,
  Title,
  Title2,
  setParentModal,
  parentModal,
}) => {
  //   const [] = useState(true);
  const { buyFundsPopUp, setBuyFundsPopUp, generalPopUpCheck } =
    useContext(OpenFundsModal);

  useEffect(() => {
    let interval;

    if (!buyFundsPopUp && !parentModal) {
      interval = setInterval(() => {
        setOpen((prevOpen) => !prevOpen);
      }, 10000); // Adjust the duration as needed
    } else {
      setOpen(false);
    }

    return () => clearInterval(interval);
  }, [buyFundsPopUp, setOpen]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Container>
      {open &&
        !buyFundsPopUp &&
        !sessionStorage.getItem("curr") &&
        !sessionStorage.getItem("single") &&
        !parentModal &&
        generalPopUpCheck && (
          <Popover
            content={
              <>
                {/* <a style={{ color: Colors.font1 }} onClick={hide}>
                  {" "}
                  <div style={{ width: "100%", textAlign: "end" }}>
                     <GrClose size={17} />
                    {" "}
                  </div>
                  {" "}
                </a> */}
              </>
            }
            title={
              <div
                style={{
                  color: Colors.font1,
                  fontSize: "0.85rem",
                  textAlign: "center",
                }}
              >
                {Title} <br /> {Title2}{" "}
              </div>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <></>
          </Popover>
        )}
    </Container>
  );
};

export default PopOver;

const Container = styled.div``;
