import React, { useContext, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import styled from "styled-components";
import { Colors } from "../Style/Colours";
import { CodeContext } from "../Context/CodeContextProvider1";

function CheckCode({ fetchData }) {
  const [eventCode, setEventCode] = useState("");
  const { code, updateCode } = useContext(CodeContext);

  const handleSpray = () => {
    updateCode(eventCode);
    // getCode.current = eventCode;
    // fetchData()
    // console.log(getCode.current);
  };
  return (
    <>
      <StyledModalBackground>
        <StyledModalContent padding="1rem"  width="70%">
          <Container>
            <aside>
              <div className="important">
                Enter an <strong>Event Code</strong> to join the{" "}
                <strong>Party</strong>
              </div>
              <div className="text">{/* Insert  */}</div>

              <div>
                <label>Event Code</label>
                <input
                  type="number"
                  required
                  placeholder="enter event code"
                  onChange={(e) => setEventCode(e?.target?.value)}
                />
              </div>
              {/* </div> */}

              <Button
                color="white"
                backgroundcolor={Colors.PrimaryColor}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={handleSpray}
                style={{ margin: "2rem 0 .5rem 0", fontWeight: "600" }}
              >
                Proceed
              </Button>
            </aside>
          </Container>
        </StyledModalContent>
      </StyledModalBackground>
    </>
  );
}

export default CheckCode;

const Container = styled.div`
  .important {
    color: #212121;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }

  .text {
    color: #626060;
    text-align: center;
    font-size: 0.9rem;
  }
`;
