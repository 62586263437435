import React, { createContext, useState } from "react";

export const OpenFundsModal = createContext();

function OpenFundsModalsProvider({ children }) {
  const [shareModal, setShareModal] = useState(false);
  const [acceptRequestFund, setAcceptRequestFund] = useState(false);
  const [
    showBubblePopUpWhenSprayedOtherGuest,
    setShowBubblePopUpWhenSprayedOtherGuest,
  ] = useState(false);

  const [buyFundsPopUp, setBuyFundsPopUp] = useState(true);

  //to check if any modal is close so to close the popup
  const [generalPopUpCheck, setgeneralPopUpCheck] = useState(true);

  const shareModalTrue = () => {
    setShareModal(true);
  };
  const shareModalFalse = () => {
    setShareModal(false);
  };
  const acceptRequestFundTrue = () => {
    setAcceptRequestFund(true);
  };
  const acceptRequestFundFalse = () => {
    setAcceptRequestFund(false);
  };
  const buyFundsPopUpAction = () => {
    setBuyFundsPopUp(!buyFundsPopUp);
  };

  function clearSessionStorageForOrigin(origin) {
    // Iterate over session storage keys
    Object.keys(sessionStorage).forEach((key) => {
      // Check if the key starts with the provided origin
      if (key.startsWith(origin)) {
        // Remove the key from session storage
        sessionStorage.removeItem(key);
      }
    });
  }

  return (
    <OpenFundsModal.Provider
      value={{
        shareModal,
        acceptRequestFund,
        setAcceptRequestFund,
        setShareModal,
        shareModalTrue,
        shareModalFalse,
        acceptRequestFundTrue,
        acceptRequestFundFalse,
        buyFundsPopUp,
        setBuyFundsPopUp,
        buyFundsPopUpAction,
        generalPopUpCheck,
        setgeneralPopUpCheck,
        clearSessionStorageForOrigin,

        showBubblePopUpWhenSprayedOtherGuest,
        setShowBubblePopUpWhenSprayedOtherGuest,
      }}
    >
      {children}
    </OpenFundsModal.Provider>
  );
}

export default OpenFundsModalsProvider;
