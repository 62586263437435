import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Navbar from "../Reuseable/Navbar";
import { Button, DateInput } from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";

import hundred1 from "../assets/currencency/hundred.png";
import hundred2 from "../assets/currencency/two.png";
import five from "../assets/currencency/five.png";
import thousand from "../assets/currencency/thousand.png";

import single from "../assets/currencency/single.svg";
import bundle from "../assets/currencency/bundle.svg";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ImRadioUnchecked } from "react-icons/im";
import BtnLoader from "../Reuseable/BtnLoader";
import Progrees from "../Reuseable/Progrees";

function Spray() {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [singleValue, setSingleValue] = useState(null);
  const [moneyValue, setMoneyValue] = useState(null);
  // const inputValue = useRef('');
  const [isDisabled, setisDisabled] = useState(true);
  const [loading, setloading] = useState(false);
  // const moneyArr = [
  //   { amount: "20000", id: 1 },
  //   { amount: "50000", id: 2 },
  //   { amount: "100000", id: 3 },
  //   { amount: "200000", id: 4 },
  //   { amount: "500000", id: 5 },
  //   { amount: "1000000", id: 6 },
  // ];

  const money = [
    {
      amount: "100 Note",
      curr: 100,
      status: false,
      img: hundred1,
      id: 1,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "200 Note",
      curr: 200,
      status: false,
      img: hundred2,
      id: 2,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "500 Note",
      curr: 500,
      status: false,
      img: five,
      id: 3,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "1000 Note",
      curr: 1000,
      status: false,
      img: thousand,
      id: 4,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
  ];

  useEffect(() => {
    const storedAmount = sessionStorage.getItem("curr");
    setSelectedAmount(storedAmount);

    const storedSingle = sessionStorage.getItem("single");
    setSingleValue(storedSingle);

    const storedMoney = localStorage?.getItem("a1mount2");
    setMoneyValue(storedMoney);
    // console.log(moneyValue);
    if (
      selectedAmount !== null &&
      singleValue !== null
      // moneyValue !== null
    ) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }, [selectedAmount, singleValue]);

  const handleButtonClick = (amount) => {
    sessionStorage.setItem("curr", amount?.amount);
    sessionStorage.setItem("curr2", amount?.curr);
    setSelectedAmount(amount?.amount);
    sessionStorage.removeItem("single");
    setSingleValue(null);
  };

  const handleSingleClick = (amount) => {
    sessionStorage.setItem("single", amount?.singleText);
    setSingleValue(amount?.singleText);
  };

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  // const handleMoneyClick = (amount) => {
  //   // inputValue.current = ''
  //   sessionStorage.setItem("money", amount?.amount);
  //   setSingleValue(amount?.amount);

  // };

  // const handleGetInputValue = (e) => {
  //   sessionStorage.removeItem('money')
  //   inputValue.current = e?.target?.value

  //   sessionStorage.setItem("money", inputValue.current);
  // }

  // console.log(inputValue);

  const handleStartSpray = (e) => {
    e?.preventDefault();
    window?.location?.assign("/live");
  };

  return (
    <>
      <Navbar text="Spray Host" />

      <Container>
        <div style={{ paddingBottom: "1.3rem" }}>
          {" "}
          <Progrees type="4" />
        </div>
        <section>
          {/* {moneyArr?.map((item) => {
            return (
              <div className="btn1" key={item?.id}>
                <Button
                  width="100%"
                  color={moneyValue === item?.amount ? "white" : `#494949`}
                  padding="1rem .4rem"
                  type="submit"
                  fontsize1="1rem"
                  radius=".3rem"
                  shadow={`3px 0px 5px${Colors.BoxShadow}`}
                  style={{ fontWeight: "500" }}
                  shadow1={`3px 0px 25px #eee`}
                  backgroundcolor={
                    moneyValue === item?.amount ? Colors?.PrimaryColor : `white`
                  }
                  onClick={() => handleMoneyClick(item)}
                >
                  ₦ {item?.amount}
                </Button>{" "}
              </div>
            );
          })} */}

          <div style={{ marginTop: "1rem" }}>Amount funded</div>
          <div className="input1">
            <DateInput
              padding=".8rem"
              width="100%"
              type="text"
              fontsize1="1rem"
              value={formatNumber(moneyValue)}
              readOnly
              placeholder="₦10,000"
            />
          </div>
        </section>

        <div>
          <div className="text">
            Select what Denomination you would like to spray and specify if in
            bundle or single notes
          </div>

          <div>
            {money?.map((d) => {
              return (
                <div style={{ marginTop: "1rem" }} key={d.id}>
                  <Button
                    width="100%"
                    align="space-between"
                    color="#494949"
                    padding=".6rem"
                    type="submit"
                    fontsize1="1rem"
                    radius=".3rem"
                    shad="none"
                    border={
                      selectedAmount === d?.amount
                        ? "2px solid #008080"
                        : `2px solid #ccc`
                    }
                    onClick={() => handleButtonClick(d)}
                  >
                    <aside>
                      <img src={d?.img} alt="" />
                    </aside>
                    <aside
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: Colors?.black,
                      }}
                    >
                      {d?.amount}
                    </aside>
                    <aside
                      className="iconcheck"
                      style={{
                        color:
                          d?.status || selectedAmount === d?.amount
                            ? "#008080"
                            : "",
                      }}
                    >
                      {d?.status || selectedAmount === d?.amount ? (
                        <BsFillCheckCircleFill />
                      ) : (
                        <ImRadioUnchecked />
                      )}
                    </aside>
                  </Button>

                  <aside style={{ transition: "linear .5s" }}>
                    {selectedAmount === d?.amount && (
                      <>
                        <aside className="typeText text">
                          Select if in bundle or in single notes
                        </aside>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {d?.type?.map((d, i) => {
                            return (
                              <>
                                <aside className="type" key={i}>
                                  <Button
                                    width="100%"
                                    align="center"
                                    color="#494949"
                                    padding=".6rem .3rem"
                                    type="submit"
                                    fontsize1="1rem"
                                    radius=".4rem"
                                    shad="none"
                                    border={
                                      singleValue === d?.singleText
                                        ? "1px solid #008080"
                                        : `1px solid #ccc`
                                    }
                                    onClick={() => handleSingleClick(d)}
                                  >
                                    <aside className="singlImg">
                                      {" "}
                                      <img src={d?.singlee} alt="single" />
                                    </aside>
                                    <aside className="typeText2">
                                      {d?.singleText}
                                    </aside>
                                  </Button>
                                </aside>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </aside>
                </div>
              );
            })}
          </div>
        </div>

        <Button
          color="white"
          backgroundcolor={isDisabled ? "#ffe1cc" : Colors.PrimaryColor}
          padding=".8rem"
          width="100%"
          type="submit"
          fontsize1=".9rem"
          radius="2rem"
          disabled={isDisabled}
          onClick={handleStartSpray}
          style={{ margin: "3rem 0 0 0", fontWeight: "600" }}
        >
          {loading ? <BtnLoader /> : "Start spraying"}
        </Button>
      </Container>
    </>
  );
}

export default Spray;

const Container = styled.div`
padding: 1.2rem 1rem;

section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;                          
}

.btn1{
   width: 31%;
   margin-top: .8rem;                         
}

.text{
  color: ${Colors?.font3};
  font-size: .95rem;                            
}

.input1{
   margin: 1rem 0;
   width: 100%;                           
}

.iconcheck{
   font-size: 1.3rem; 
   color: #ccc;                          
}

.type{
     width: 48%;                         
}

.singlImg img{                        
    height: 50px;                          
}

.typeText{
  padding: 1rem 0 .5rem 0;                        
}

.typeText2{
  font-size: .8rem;
}

`;
