import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
// import BtnLoader from "../Reuseable/BtnLoader";
import { Colors } from "../Style/Colours";
import styled from "styled-components";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import baseURL from "../Request/BaseUrl";
import { secret } from "../Request/Secret";
import { post } from "../Request/GetAPI";
// import ThankYou from "../assets/AnimatedSVG/thank you gif.gif";
import ThankYouMessage from "./ThankYouMessage";
import { toast } from "sonner";
import axios from "axios";
import { LuArrowRightFromLine } from "react-icons/lu";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";
import { CodeContext } from "../Context/CodeContextProvider1";
import WithdrawFunds from "./WithdrawFunds";

function ThankYouModal({data}) {
  const [isopen, setIsopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thanks, setThanks] = useState(true);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState(false);

  const getCode = JSON.parse(sessionStorage.getItem("details"));
  let RemainingBalance = parseFloat(localStorage.getItem("a1mount2"));
  // let MoneySprayed = parseFloat(sessionStorage.getItem("balance"));

  const navigate = useNavigate();

  const { setgeneralPopUpCheck, clearSessionStorageForOrigin } =
    useContext(OpenFundsModal);

  const { setIfClickedOnLeave } = useContext(CodeContext);

  // const totalMoney = MoneySprayed + RemainingBalance;

  const endpoint = "/guestapi/spray/";
  const headers = {
    "Content-Type": "application/json",
  };

  const handleSpray = async () => {
    // setLoading(false);
    const data = JSON.stringify({
      data: {
        Note: RemainingBalance,
        secret,
      },
    });

    try {
      const response = await post(baseURL, endpoint, data, headers);

      if (response.status) {
        // setLoading(true);
      }
    } catch (error) {
      toast.error("Error", {
        description: "Opps!, Something went wrong, please try again!",
        style: { color: "red" },
      });
    }
  };

  const fetchUserToLeave = async () => {
    setLoading(true);
    try {
      const requestData = {
        code: getCode && getCode?.code || getDetails?.code,
        secret,
        remove: "yes",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/alluserevent/`,
        requestData,
        headers
      );

      // if (response?.data?.status) {
      //   setPeople(response?.data?.data?.["all users"]);
      setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
    }
  };

  const [getBalance, setGetBalance] = useState({
    data: 0,
    loading: false,
  });

  const fetchBalance = async () => {
    setGetBalance({ ...getBalance, loading: true });
    const data = JSON.stringify({
      data: {
        secret,
      },
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/balance/`,
        data,
        {
          headers,
        }
      );

      if (response.data.status) {
        setGetBalance({
          ...getBalance,
          data: response?.data?.data?.fund?.balance,
          loading: false,
        });
        sessionStorage.setItem(
          "balance",
          response?.data?.data?.fund?.totalSprayed
        );
      }
    } catch (error) {
      setGetBalance({ ...getBalance, loading: false });
      toast.error("", {
        description: error?.response?.data?.message,
        style: { color: "red" },
      });
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleLeave = () => {
    // handleSpray();
    fetchUserToLeave();
    setIfClickedOnLeave(false);
    toast.loading("", {
      description: "Leaving...",
      style: { color: "green" },
    });

    if (getCode.code) {
      setThanks(false);

      setTimeout(() => {
        // sessionStorage.removeItem("balance");
        sessionStorage.removeItem("curr");
        sessionStorage.removeItem("curr2");
        sessionStorage.removeItem("single");
        localStorage.removeItem("a1mount2");
        sessionStorage.removeItem("money");
        sessionStorage?.removeItem("guestImg");
        clearSessionStorageForOrigin("https://media.partytime.ng");
        navigate(`/?code=${getCode.code}`);
      }, 4000);
    }
  };

  const handleFundWallet = () => {
    // sessionStorage.removeItem("balance");
    sessionStorage.removeItem("curr");
    sessionStorage.removeItem("curr2");
    sessionStorage.removeItem("single");
    localStorage.removeItem("a1mount2");
    sessionStorage?.removeItem("guestImg");
    navigate(`/amount`);
  };

  const [message, setMessage] = useState("");

  const getDetails = JSON.parse(sessionStorage.getItem("details"));

  const fetchMessage = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/guestapi/hostmessage?code=${getDetails?.code}`,
        headers
      );

      if (response?.data?.status) {
        setMessage(response?.data?.message);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
    });
  }, []);

  const handleOpenWithdrawal = () => {
    setOpenWithdrawalModal(true);
    if (openWithdrawalModal) {
      setTimeout(() => {
        setIsopen(false);
      }, 2000);
    }
    
  }

  return (
    <Container>
      <div
        className="cancel"
        onClick={() => {
          setIsopen(true);
          fetchMessage();
          fetchBalance();
          setgeneralPopUpCheck(false);
        }}
      >
        <LuArrowRightFromLine size={30} color={Colors?.PrimaryColor} />
      </div>
     
      {isopen && (
        <StyledModalBackground
          onClick={(e) => {
            // Check if the clicked element is the background itself
            if (e.target === e.currentTarget) {
              setIsopen(false);
              setgeneralPopUpCheck(true);
            }
          }}
        >
           {openWithdrawalModal && (
        <WithdrawFunds
        openModal={openWithdrawalModal}
        setOpenModal={setOpenWithdrawalModal}
      />
      )}
          {thanks ? (
            <StyledModalContent
              width="80%"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="400"
            >
              {getBalance?.loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  {"Loading..."}
                </div>
              ) : (
                <div className="thankyouall">
                  {/* <div style={{width: '70px', margin: 'auto'}}>
            <img src={exceed} width="100%" />
          </div> */}
                  <h3 style={{ paddingBottom: "1rem" }}>
                    Close Spraying Interface?
                  </h3>

                  {parseFloat(getBalance?.data) > 0 ? (
                    <>
                      {" "}
                      <p>You still have enough money, Are you done spraying?</p>
                      
                      <Button
                        color="white"
                        backgroundcolor={Colors.PrimaryColor}
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={() => {
                          handleOpenWithdrawal();
                          // setOpenWithdrawalModal(true);
                          // setIsopen(false);
                        }}
                        style={{ margin: "3rem 0 .5rem 0" }}
                      >
                        Withdraw Funds
                      </Button>
                      <Button
                        color="black"
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        border={`1px solid ${Colors?.font1}`}
                        shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={handleLeave}
                        style={{ margin: ".5rem 0 .5rem 0" }}
                      >
                        Yes, I'm done
                      </Button>
                      <Button
                        color="black"
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        // border={`1px solid ${Colors?.font1}`}
                        // shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={() => {
                          setIsopen(false);
                          setgeneralPopUpCheck(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <p>You are you sure you want to leave?</p>
                      <Button
                        color="white"
                        backgroundcolor={Colors.PrimaryColor}
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={handleFundWallet}
                        style={{ margin: "3rem 0 .5rem 0" }}
                      >
                        Buy new notes
                      </Button>
                      <Button
                        color="black"
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        border={`1px solid ${Colors?.font1}`}
                        shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={handleLeave}
                        style={{ margin: ".5rem 0 .5rem 0" }}
                      >
                        Yes, I'm done
                      </Button>
                      <Button
                        color="black"
                        padding=".6rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius=".5rem"
                        // backgroundcolor={Colors.font3}
                        // border={`1px solid ${Colors?.font1}`}
                        // shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={() => {
                          setIsopen(false);
                          setgeneralPopUpCheck(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              )}
            </StyledModalContent>
          ) : (
            <ThankYouMessage message={message} />
            // <div data-aos="zoom-in-down" style={{ width: "100%", margin: "auto" }}>
            //   <img src={ThankYou} width="100%" />
            // </div>
          )}
        </StyledModalBackground>
      )}
    </Container>
  );
}

export default ThankYouModal;

const Container = styled.div`
  .cancel {
    margin-bottom: 0.4rem;
    display: flex;
    border-radius: 50%;
    color: white;
    background: rgba(256, 256, 256, 0.5);
    padding: 0.4rem;
    cursor: pointer;
    opacity: 1;
    transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
    box-shadow: 0px 0px 10px #414141; /* Initial box-shadow */

    &:hover {
      box-shadow: 0px 0px 20px #414141;
    }
  }

  .thankyouall {
    padding: 1rem;
    text-align: center;
  }
`;
