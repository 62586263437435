import React, { useContext, useEffect, useState } from "react";

import GuestIcon from "../assets/live/guestListIcon.png";
import styled from "styled-components";
import Aos from "aos";
import {
  Button,
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import avata from "../assets/live/vector.svg";
import axios from "axios";
import { HiOutlineDotsVertical } from "react-icons/hi";
import RequestFunds from "./RequestFunds";
import ShareFunds from "./ShareFunds";
import { secret } from "../Request/Secret";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";
import { HiUserGroup } from "react-icons/hi";
import { toast } from "sonner";

function FundWallet({Img, setImg }) {
  const [showModal, setShowModal] = useState(false);
  const [getGuestPerValue, setgetGuestPerValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [people, setPeople] = useState([]);

  const getCode = sessionStorage.getItem("code");
  const getDetails = JSON.parse(sessionStorage.getItem("details"));
  const { shareModal, setShareModal, setgeneralPopUpCheck } =
    useContext(OpenFundsModal);
  const displayName = sessionStorage.getItem("displayName");

  const headers = {
    "Content-Type": "application/json",
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestData = {
        code:
          getCode !== null && getCode !== "null" ? getCode : getDetails?.code,
        secret,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/alluserevent/`,
        requestData,
        headers
      );

      if (response?.data?.status) {
        setPeople(response?.data?.data?.["all users"]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleShowRequestBtn = (item) => {
    if (getGuestPerValue === item?.username) {
      setgetGuestPerValue("");
    } else {
      setgetGuestPerValue(item?.username);
    }
  };

  useEffect(() => {
    fetchData();
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  // Get the total window height
  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <Container>
      <div
        onClick={() => {
          setShowModal(true);
          setgetGuestPerValue("");
          fetchData();
          setgeneralPopUpCheck(false);
        }}
      >
        {/* <img src={GuestIcon} alt="" /> */}
        <HiUserGroup size={30} />
      </div>

      {showModal && (
        <StyledModalBackground1
          height={`cal(100vh - ${headerHeight}px)`}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowModal(false);
              setgeneralPopUpCheck(true);
            }
          }}
        >
          <StyledModalContent
            padding="1rem 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <div className="header">
              <div
                style={{
                  width: "20%",
                  color: Colors.font3,
                  fontSize: "0.8rem",
                }}
              ></div>
              <div style={{ width: "60%", margin: "auto" }}>All Guests</div>
              <div
                style={{ width: "20%" }}
                className="Close"
                onClick={() => {
                  setShowModal(false);
                  setgeneralPopUpCheck(true);
                }}
              >
                Close
              </div>
            </div>

            <div
              style={{
                padding: ".9rem .6rem 2rem .6rem",
                background: "#f9f9f9",
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20vh",
                  }}
                >
                  {"Loading..."}
                </div>
              ) : (
                <>
                {/* Img && */}
                  { sessionStorage?.getItem("guestImg") && Img && (
                    <div
                      className={`leader`}
                      style={{
                        borderRadius: "1.5rem",
                        margin: ".5rem 0",
                        padding: " .1rem .5rem .1rem .1rem",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "50px", height: "50px" }}>
                          <img
                            src={getDetails && getDetails?.hostImg}
                            alt=""
                            className="avata"
                            style={{ objectFit: "contain" }}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div
                          style={{
                            fontWeight: "600",
                            paddingLeft: "1rem",
                          }}
                        >
                          <span style={{ color: Colors.font3 }}>Host</span>
                        </div>
                      </div>
                      <div>
                        <Button
                          color={Colors.white}
                          backgroundcolor={Colors?.PrimaryColor}
                          padding=".8rem"
                          // width="32%"
                          type="submit"
                          fontsize1=".8rem"
                          radius="2rem"
                          //   disabled={isDisabled}
                          //   ref={buttonRef}
                          onClick={() => {
                            sessionStorage?.removeItem("guestImg");
                            setImg("");
                            setShowModal(false);
                            toast.success("", {
                              description: `Back Spraying Host`,
                              style: { color: "teal" },
                            });
                          }}
                          style={{ fontWeight: "600" }}
                        >
                          Spray Host
                        </Button>
                      </div>
                    </div>
                  )}
                  {people &&
                    people.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            className={`leader`}
                            style={{
                              borderRadius:
                                item?.username === getGuestPerValue
                                  ? "1.5rem 1.5rem 0 0"
                                  : "1.5rem",
                              margin:
                                item?.username === getGuestPerValue
                                  ? "0"
                                  : ".5rem 0",
                              padding:
                                item?.username === getGuestPerValue
                                  ? " .3rem 1rem .3rem .3rem"
                                  : " .1rem 1rem .1rem .1rem",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "50px", height: "50px" }}>
                                <img
                                  src={
                                    item.avatar === null || !item?.avatar
                                      ? avata
                                      : item?.avatar
                                  }
                                  alt=""
                                  className="avata"
                                  style={{ objectFit: "contain" }}
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                              <div
                                style={{
                                  fontWeight: "600",
                                  paddingLeft: "1rem",
                                }}
                              >
                                {/* <span style={{ color: Colors.font3 }}>
                              {item.username}
                            </span> */}

                                {displayName === item.username ? (
                                  <span
                                    style={{
                                      color: Colors.font3,
                                      fontSize: "1.1rem",
                                    }}
                                  >
                                    You
                                  </span>
                                ) : (
                                  <span style={{ color: Colors.font3 }}>
                                    {item.username}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              {displayName === item.username ? (
                                <div></div>
                              ) : (
                                <div
                                  className="d"
                                  onClick={() => handleShowRequestBtn(item)}
                                >
                                  <HiOutlineDotsVertical size={25} />
                                </div>
                              )}
                            </div>
                          </div>

                          {getGuestPerValue === item?.username && (
                            <div className="request">
                              <Button
                                color={Colors.font3}
                                backgroundcolor="#dedede"
                                padding=".8rem"
                                width="32%"
                                type="submit"
                                fontsize1=".8rem"
                                radius="2rem"
                                onClick={() => {
                                  sessionStorage?.setItem(
                                    "guestImg",
                                    JSON?.stringify(item)
                                  );
                                  setImg(item);
                                  setShowModal(false);
                                  toast.success("", {
                                    description: `Now Spraying [ ${item?.username} ]`,
                                    style: { color: "teal" },
                                  });
                                }}
                                style={{ fontWeight: "600" }}
                              >
                                Spray User
                              </Button>

                              <Button
                                color={Colors.font3}
                                backgroundcolor="#dedede"
                                padding=".8rem"
                                width="32%"
                                type="submit"
                                fontsize1=".8rem"
                                radius="2rem"
                                //   disabled={isDisabled}
                                //   ref={buttonRef}
                                onClick={() => {
                                  setShareModal(true);
                                  // setShowModal(false)
                                }}
                                style={{ fontWeight: "600" }}
                              >
                                Gift funds
                              </Button>
                              {shareModal && (
                                <ShareFunds
                                  item={item}
                                  toggleParentModal={setShowModal}
                                />
                              )}
                              <RequestFunds
                                toggleParentModal={setShowModal}
                                item={item}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}{" "}
                </>
              )}

              {people.length === 0 && (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "20vh",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "600" }}>Absence of Guest</div>
                    <p
                      style={{
                        fontSize: ".9rem",
                        padding: ".5rem 0",
                        color: "#666666",
                      }}
                    >
                      {/* [ Start spray and rise to the top ] */}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </StyledModalContent>
        </StyledModalBackground1>
      )}
    </Container>
  );
}

export default FundWallet;

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 1.6rem 0 0.3rem 0;
  }

  .Close {
    color: ${Colors.PrimaryColor};
    font-size: 0.9rem;
    cursor: pointer;
  }

  .avata {
    border-radius: 50%;
  }

  .leader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-radius: 30px; */
    border-bottom: 1px solid rgba(235, 233, 233, 0.3);
    background: #fff;

    /* margin: 0.5rem 0; */
    transition: linear 0.3s;
    box-shadow: 0px 0px 10px ${Colors.lightPri};
  }

  .request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #343434;
    padding: 0.5rem;
    border-radius: 0 0 1.5rem 1.5rem;
  }
`;
