import React, { createContext, useEffect, useRef, useState } from "react";
import { secret } from "../Request/Secret";
import baseURL from "../Request/BaseUrl";
import { toast } from "sonner";
import axios from "axios";

export const FetchBalanceContext = createContext();

function FetchBalanceProvider({ children }) {
  //for switching the spray money element
  // const [show, setShow] = useState(false);
  const show = useRef(false);
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);

  const storedMoney = parseFloat(localStorage.getItem("a1mount2") || 0); // Parse float or default to 0
  const amountRef = useRef(storedMoney);

  const balanceRef = useRef(parseFloat(sessionStorage.getItem("balance")) || 0);
  const checkIfFundBeforeSprpray = sessionStorage?.getItem("fund");

  const headers = {
    "Content-Type": "application/json",
  };

  const fetchBalance = async () => {
    // setLoading(true);
    const data = JSON.stringify({
      data: {
        secret,
      },
    });

    try {
      const response = await axios.post(`${baseURL}/guestapi/balance/`, data, {
        headers,
      });

      if (response.data.status) {
        localStorage.setItem("a1mount2", response?.data?.data?.fund?.balance);
        localStorage.setItem(
          "balance",
          response?.data?.data?.fund?.totalSprayed
        );

        // getBalanceNew.current = response?.data?.data?.fund?.balance;
        // setLoading(false);

        amountRef.current = response?.data?.data?.fund?.balance;
        balanceRef &&
          (balanceRef.current =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.fund &&
            response.data.data.fund.totalSprayed);

        // console.log(getBalanceNew.current);
        if (response?.data?.data?.fund?.balance > 0) {
          if (
            !sessionStorage.getItem("curr") &&
            !sessionStorage.getItem("curr2") &&
            !sessionStorage.getItem("single")
          ) {
            sessionStorage.setItem("curr", "100 Note");
            sessionStorage.setItem("curr2", "100");
            sessionStorage.setItem("single", "Single notes");
          }

          show.current = true;
          setPopUp(false);
        } else {
          // setShow(false);
          show.current = false;
          if (!checkIfFundBeforeSprpray) {
            setPopUp(true);
          }
        }
      }
    } catch (error) {
      // setLoading(false);
      //   toast.error("", {
      //     description: error?.response?.data?.message,
      //     style: { color: "red" },
      //   });
    }
  };

  const fetchBalanceWithoutRefundPopUp = async () => {
    setLoading(true);
    const data = JSON.stringify({
      data: {
        secret,
      },
    });

    try {
      const response = await axios.post(`${baseURL}/guestapi/balance/`, data, {
        headers,
      });

      if (response.data.status) {
        localStorage.setItem("a1mount2", response?.data?.data?.fund?.balance);
        localStorage.setItem(
          "balance",
          response?.data?.data?.fund?.totalSprayed
        );

        setLoading(false);

        amountRef.current = response?.data?.data?.fund?.balance;
        balanceRef &&
          (balanceRef.current =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.fund &&
            response.data.data.fund.totalSprayed);

        // if (response?.data?.data?.fund?.balance > 0) {
        //   show.current = true;
        //   setPopUp(false)
        // } else {
        //   // setShow(false);
        //   show.current = false;
        //   if (!checkIfFundBeforeSprpray) {
        //     setPopUp(true)
        //   }
        // }
      }
    } catch (error) {
      setLoading(false);
      //   toast.error("", {
      //     description: error?.response?.data?.message,
      //     style: { color: "red" },
      //   });
    }
  };

  const fetchBalanceOnlyWhenBalanceIs0 = async () => {
    // setLoading(true);
    const data = JSON.stringify({
      data: {
        secret,
      },
    });

    try {
      const response = await axios.post(`${baseURL}/guestapi/balance/`, data, {
        headers,
      });

      if (response.data.status) {
        localStorage.setItem("a1mount2", response?.data?.data?.fund?.balance);
        localStorage.setItem(
          "balance",
          response?.data?.data?.fund?.totalSprayed
        );

        // setLoading(false);

        amountRef.current = response?.data?.data?.fund?.balance;
        balanceRef &&
          (balanceRef.current =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.fund &&
            response.data.data.fund.totalSprayed);

        if (Number(response?.data?.data?.fund?.balance) > 0) {
          show.current = true;
          setPopUp(false);
        }else{
          show.current = false;
          setPopUp(true);
        }
      }
    } catch (error) {
      // setLoading(false);
      //   toast.error("", {
      //     description: error?.response?.data?.message,
      //     style: { color: "red" },
      //   });
    }
  };
  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    fetchBalanceWithoutRefundPopUp();
  }, []);

  useEffect(() => {
    if (loading) {
      fetchBalanceWithoutRefundPopUp();
    }
  }, [loading]);

  return (
    <FetchBalanceContext.Provider
      value={{
        show,
        // setShow,
        fetchBalance,
        amountRef,
        balanceRef,
        popUp,
        setPopUp,
        fetchBalanceWithoutRefundPopUp,
        setLoading,
        loading,
        fetchBalanceOnlyWhenBalanceIs0,
      }}
    >
      {children}
    </FetchBalanceContext.Provider>
  );
}

export default FetchBalanceProvider;
