import MoneyIcon from "../../assets/live/moneyicon.png";
import { useContext, useEffect, useRef, useState } from "react";
import hundred1 from "../../assets/currencency/hundred.png";
import hundred2 from "../../assets/currencency/two.png";
import five from "../../assets/currencency/five.png";
import thousand from "../../assets/currencency/thousand.png";

import single from "../../assets/AnimatedSVG/singleMonAni.gif";
import bundle from "../../assets/AnimatedSVG/bundleMonAni.gif";
import { secret } from "../../Request/Secret";
import { toast } from "sonner";
import axios from "axios";
import {
  Button,
  DateInput,
  StyledModalBackground1,
  StyledModalContent,
} from "../../Reuseable/StyledComponents";
import { GrClose } from "react-icons/gr";
import LazyImage from "../../Reuseable/LazyImage";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ImRadioUnchecked } from "react-icons/im";
import { Colors } from "../../Style/Colours";
import styled from "styled-components";
import baseURL from "../../Request/BaseUrl";
import { FetchBalanceContext } from "../../Context/FetchBalanceProvider";

function SelectDinominationModal() {
  const [checkCurrency, setCheckCurrency] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [singleValue, setSingleValue] = useState(null);
  // const [moneyValue, setMoneyValue] = useState(null);
  const [isDisabled, setisDisabled] = useState(true);
  const [loading, setloading] = useState(false);
  const buttonRef = useRef(null);

  const money = [
    {
      amount: "100 Note",
      curr: 100,
      status: false,
      img: {
        src: hundred1,
        width: "100%",
      },
      id: 1,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "200 Note",
      curr: 200,
      status: false,
      img: {
        src: hundred2,
        width: "100%",
      },
      id: 2,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "500 Note",
      curr: 500,
      status: false,
      img: {
        src: five,
        width: "100%",
      },
      id: 3,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
    {
      amount: "1000 Note",
      curr: 1000,
      status: false,
      img: {
        src: thousand,
        width: "100%",
      },
      id: 4,
      type: [
        {
          singlee: single,
          singleText: "Single notes",
        },
        {
          singlee: bundle,
          singleText: "Bundle of cash",
        },
      ],
    },
  ];

  const { fetchBalance, amountRef, show } = useContext(FetchBalanceContext);
 
  useEffect(() => {
    fetchBalance()
  }, []);

  console.log('demoFirst');

  useEffect(() => {
    const storedAmount = sessionStorage.getItem("curr");
    setSelectedAmount(storedAmount);

    const storedSingle = sessionStorage.getItem("single");
    setSingleValue(storedSingle);

    // const storedMoney = loading ? "" : localStorage?.getItem("a1mount2");
    // setMoneyValue(storedMoney);

    if (
      selectedAmount !== null &&
      singleValue !== null
      // moneyValue !== null
    ) {
      setisDisabled(false);
      if (buttonRef.current) {
        buttonRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setisDisabled(true);
    }
  }, [selectedAmount, singleValue, buttonRef.current]);

  const handleButtonClick = (amount) => {
    sessionStorage.setItem("curr", amount?.amount);
    sessionStorage.setItem("curr2", amount?.curr);
    setSelectedAmount(amount?.amount);
    sessionStorage.removeItem("single");
    setSingleValue(null);
  };

  const handleSingleClick = (amount) => {
    sessionStorage.setItem("single", amount?.singleText);
    setSingleValue(amount?.singleText);
  };

  const handleStartSpray = (e) => {
    show.current = true;
    setCheckCurrency(false);
   
  };

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  useEffect(() => {
    if (sessionStorage.getItem("curr") && !sessionStorage.getItem("single")) {
      toast.info("", {
        description: "Select note type ( single or bundle ) to start spraying",
        style: { color: "teal" },
      });
    }
  }, [sessionStorage.getItem("curr")]);

  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <>
      {checkCurrency && (
        <StyledModalBackground1
          height={`cal(100vh - ${headerHeight}px)`}
          onClick={(e) => {
            // Check if the clicked element is the background itself
            if (
              e.target === e.currentTarget &&
              sessionStorage.getItem("curr") &&
              sessionStorage.getItem("single")
            ) {
              setCheckCurrency(false);
            }
            if (e.target === e.currentTarget) {
              if (
                !sessionStorage.getItem("curr") ||
                !sessionStorage.getItem("single")
              ) {
                toast.error("", {
                  description: "Select denomination and note type to continue",
                  style: { color: "red" },
                });
              }
            }
          }}
        >
          <StyledModalContent
            padding="0 0 4rem 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
            height780="100vh"

            // style={{ma}}
          >
            <Container>
              {
                //   sessionStorage.getItem("curr") &&
                //     sessionStorage.getItem("single") && (
                <div
                  onClick={() => setCheckCurrency(false)}
                  style={{
                    position: "sticky",
                    top: "0%",
                    right: "0%",
                    zIndex: "99",
                    // width: "fit-content",
                    display: "flex",
                    justifyContent: "end",
                    // padding: '1rem'
                  }}
                >
                  {" "}
                  <div
                    style={{
                      background: "white",
                      borderRadius: ".4rem",
                      display: "flex",
                      padding: ".6rem",
                      boxShadow: "0px 0px 10px #e0dfdf",
                    }}
                  >
                    <GrClose size={25} />
                  </div>
                </div>
                // )
              }

              <div style={{ marginTop: "1rem" }}>Amount funded</div>
              <div className="input1">
                <DateInput
                  padding=".8rem"
                  width="100%"
                  type="text"
                  fontsize1="1rem"
                  value={formatNumber(amountRef?.current || 0)}
                  readOnly
                  placeholder="₦10,000"
                />
              </div>

              <div>
                <div className="text">
                  Select what Denomination you would like to spray and specify
                  if in bundle or single notes
                </div>

                <div>
                  {money?.map((d) => {
                    return (
                      <div style={{ marginTop: "1rem" }} key={d.id}>
                        <Button
                          width="100%"
                          align="space-between"
                          color="#494949"
                          padding=".6rem"
                          type="submit"
                          fontsize1="1rem"
                          radius=".3rem"
                          shad="none"
                          border={
                            selectedAmount === d?.amount
                              ? "2px solid #008080"
                              : `2px solid #ccc`
                          }
                          onClick={() => handleButtonClick(d)}
                        >
                          <aside>
                            {/* <img src={d?.img?.lazyImagePropsHostImg} alt="" loading="lazy" /> */}
                            <LazyImage image={d?.img} />
                          </aside>
                          <aside
                            style={{
                              fontSize: "1.1rem",
                              fontWeight: "500",
                              color: Colors?.black,
                            }}
                          >
                            {d?.amount}
                          </aside>
                          <aside
                            className="iconcheck"
                            style={{
                              color:
                                d?.status || selectedAmount === d?.amount
                                  ? "#008080"
                                  : "",
                            }}
                          >
                            {d?.status || selectedAmount === d?.amount ? (
                              <BsFillCheckCircleFill />
                            ) : (
                              <ImRadioUnchecked />
                            )}
                          </aside>
                        </Button>

                        <aside style={{ transition: "linear .5s" }}>
                          {selectedAmount === d?.amount && (
                            <>
                              <aside className="typeText text">
                                Select if in bundle or in single notes
                              </aside>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {d?.type?.map((d, i) => {
                                  return (
                                    <>
                                      <aside className="type" key={i}>
                                        <Button
                                          width="100%"
                                          align="center"
                                          color="#494949"
                                          padding=".6rem .3rem"
                                          type="submit"
                                          fontsize1="1rem"
                                          radius=".4rem"
                                          shad="none"
                                          border={
                                            singleValue === d?.singleText
                                              ? "2px solid #008080"
                                              : `2px solid #ccc`
                                          }
                                          onClick={() => handleSingleClick(d)}
                                        >
                                          <aside className="singlImg">
                                            {" "}
                                            <img
                                              src={d?.singlee}
                                              alt="single"
                                              width="100%"
                                              style={{ objectFit: "contain" }}
                                              loading="lazy"
                                            />
                                          </aside>
                                          <aside className="typeText2">
                                            {d?.singleText}
                                          </aside>
                                        </Button>
                                      </aside>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </aside>
                      </div>
                    );
                  })}
                </div>
              </div>

              <Button
                color="white"
                backgroundcolor={isDisabled ? "#ffe1cc" : Colors.PrimaryColor}
                padding=".8rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                disabled={isDisabled}
                ref={buttonRef}
                onClick={handleStartSpray}
                style={{ margin: "3rem 0 0 0", fontWeight: "600" }}
              >
                {"Start spraying"}
              </Button>
            </Container>
          </StyledModalContent>
        </StyledModalBackground1>
      )}
    </>
  );
}

export default SelectDinominationModal;

const Container = styled.div`
  padding: 1.2rem 1rem;

  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  .btn1 {
    width: 31%;
    margin-top: 0.8rem;
  }

  .text {
    color: ${Colors?.font3};
    font-size: 0.95rem;
  }

  .input1 {
    margin: 1rem 0;
    width: 100%;
  }

  .iconcheck {
    font-size: 1.3rem;
    color: #ccc;
  }

  .type {
    width: 48%;
  }

  .singlImg img {
    // height: 50px;
    // background: red;
    transform: scale(1.1);
  }
  .singlImg {
    height: 50px;
    width: 50px;
  }

  .typeText {
    padding: 1rem 0 0.5rem 0;
  }

  .typeText2 {
    font-size: 0.8rem;
  }
`;
