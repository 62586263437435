import React from "react";
import { styled } from "styled-components";
import Navbar from "../Reuseable/Navbar";
import NumericKeyboard from "../Reuseable/ButtonInput";

function Amount() {
  const namee = sessionStorage?.getItem("name");

  return (
    <Container>
      <Navbar text={` Buy New Note`} />
      <NumericKeyboard />
    </Container>
  );
}

export default Amount;

const Container = styled.section`
  height: 100vh;

  @media screen and (min-width: 650px) {
    display: grid;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }

  
`;
