import React, { createContext, useState } from "react";

export const ModalContext = createContext();

function ModalContextProvider({ children }) {
  const [isopen, setIsopen] = useState(false);
  return (
    <ModalContext.Provider value={{ isopen, setIsopen }}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContextProvider;
