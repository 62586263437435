import React, { useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import styled from "styled-components";
import { toast } from "sonner";
import axios from "axios";
import { secret } from "../Request/Secret";

function WithdrawFunds({ setOpenModal, openModal }) {
  const getCode = sessionStorage.getItem("code");
  const getDetails = JSON.parse(sessionStorage.getItem("details"));

  const displayName = sessionStorage.getItem("displayName");

  const [getBalance, setGetBalance] = useState({
    data: "",
    loading: false,
  });

  const headers = {
    "Content-Type": "application/json",
  };

  const [allGuest, setAllGuest] = useState({
    data: [],
    loading: false,
  });

  const fetchAllGuest = async () => {
    setAllGuest({
      ...allGuest,
      loading: true,
    });
    try {
      const requestData = {
        code:
          getCode !== null && getCode !== "null" ? getCode : getDetails?.code,
        secret,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/alluserevent/`,
        requestData,
        headers
      );

      if (response?.data?.status) {
        setAllGuest({
          ...allGuest,
          data: response?.data?.data?.["all users"],
          loading: false,
        });
      }
    } catch (error) {
      setAllGuest({
        ...allGuest,
        loading: false,
      });
    }
  };

  useEffect(() => {
    fetchAllGuest();
  }, []);

  let newData = {};

  allGuest?.data.forEach((user) => {
    if (user.username === displayName && displayName) {
      newData = { ...user };
    }
  });

  const handleCodeCopy = () => {
    try {
      const textField = document.createElement("textarea");
      // textField.innerText = newData?.id;
      textField.innerText = secret;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      toast.success("Code Copied");
    } catch (error) {
      toast.error("Failed to copy Code. Please try again.");
    }
  };

  return (
    <Container>
      {openModal && (
        <StyledModalBackground
          background="rgba(0, 0, 0, 0.1)"
          onClick={(e) => {
            // Check if the clicked element is the background itself
            // if (e.target === e.currentTarget) {
            //   setOpenModal(false);
            // }
          }}
        >
          <StyledModalContent
            width="85%"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            {getBalance?.loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                {"Loading..."}
              </div>
            ) : (
              <div className="thankyouall">
                <h3 style={{ paddingBottom: "1rem" }}>Here’s your code</h3>

                <>
                  <div
                    className="copy"
                    style={{ color: "#898989", fontSize: ".9rem" }}
                  >
                    Copy and paste this code on the required field when signing
                    up
                  </div>

                  <aside
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#F6F6F9",
                      padding: ".7rem",
                      marginTop: "1.3rem",
                      borderRadius: ".3rem",
                    }}
                  >
                    {/* {allGuest?.loading ? (
                      "Loading..."
                    ) : (
                      <>
                        <div
                          style={{
                            color: "#626060",
                            textAlign: "center",
                            fontSize: "1.1rem",
                          }}
                        >
                          {secret}
                        </div>

                        <Button
                          color="#626060"
                          padding=".7rem 1.2rem"
                          type="submit"
                          fontsize1=".9rem"
                          radius="2rem"
                          border={`1px solid#E0E0E0`}
                          shadow={`3px 0px 5px${Colors.BoxShadow}`}
                          onClick={handleCodeCopy}
                          style={{ margin: "1rem 0 0 0" }}
                        >
                          Copy
                        </Button>
                      </>
                    )} */}
                    <>
                        <div
                          style={{
                            color: "#626060",
                            textAlign: "center",
                            fontSize: "1.1rem",
                          }}
                        >
                          {secret}
                        </div>

                        <Button
                          color="#626060"
                          padding=".7rem 1.2rem"
                          type="submit"
                          fontsize1=".9rem"
                          radius="2rem"
                          border={`1px solid#E0E0E0`}
                          shadow={`3px 0px 5px${Colors.BoxShadow}`}
                          onClick={handleCodeCopy}
                          style={{ margin: "1rem 0 0 0" }}
                        >
                          Copy
                        </Button>
                      </>
                  </aside>
                  <Button
                    color="white"
                    backgroundcolor={Colors.PrimaryColor}
                    padding=".6rem"
                    width="100%"
                    type="submit"
                    fontsize1=".9rem"
                    radius="2rem"
                    shadow={`3px 0px 5px${Colors.BoxShadow}`}
                    onClick={() => setOpenModal(true)}
                    style={{ margin: "3rem 0 .5rem 0" }}
                  >
                    Download App
                  </Button>

                  <Button
                    color="black"
                    padding=".6rem"
                    width="100%"
                    type="submit"
                    fontsize1=".9rem"
                    radius="2rem"
                    // backgroundcolor={Colors.font3}
                    border={`1px solid ${Colors?.font1}`}
                    shadow={`3px 0px 5px${Colors.BoxShadow}`}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    Go Back
                  </Button>
                </>
              </div>
            )}
          </StyledModalContent>
        </StyledModalBackground>
      )}
    </Container>
  );
}

export default WithdrawFunds;

const Container = styled.div`
  .cancel {
    margin-bottom: 0.4rem;
    display: flex;
    border-radius: 50%;
    color: white;
    background: rgba(256, 256, 256, 0.5);
    padding: 0.4rem;
    cursor: pointer;
    opacity: 1;
    transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
    box-shadow: 0px 0px 10px #414141; /* Initial box-shadow */

    .copy {
      font-size: ".6rem";
      color: #aeaeae;
    }

    &:hover {
      box-shadow: 0px 0px 20px #414141;
    }
  }

  .thankyouall {
    padding: 1rem 0.4rem;
    text-align: center;
  }
`;
