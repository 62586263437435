import React, { useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
// import { RiErrorWarningFill } from "react-icons/ri";
import styled from "styled-components";
import BtnLoader from "../Reuseable/BtnLoader";
import { Colors } from "../Style/Colours";

import exceed from "../assets/exceed.svg";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";

function ExceededAmount({ setPopUp, popUp }) {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const getCode = sessionStorage.getItem("code");

  const navigate = useNavigate();

  const handleBackToFund = () => {
    // sessionStorage.removeItem("balance");
    sessionStorage.removeItem("curr");
    sessionStorage.removeItem("curr2");
    localStorage.removeItem("a1mount2");
    navigate(`/amount`);
  };

  const handleLeave = () => {
    if (getCode || JSON.parse(sessionStorage.getItem("details"))?.code) {
      // sessionStorage.removeItem("balance");
      sessionStorage.removeItem("curr");
      sessionStorage.removeItem("curr2");
      // navigate(`/?code=${getCode}`);
      // window?.location?.assign(`/?code=${getCode}`)
      navigate(
        `/?code=${
          getCode || JSON.parse(sessionStorage.getItem("details"))?.code
        }`
      );
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 300,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <StyledModalBackground
        onClick={(e) => {
          // Check if the clicked element is the background itself
          if (e.target === e.currentTarget) {
            setPopUp(false);
          }
        }}
      >
        <StyledModalContent
          // maxwidth="80%"
          width="80%"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          <div
            onClick={() => {
              setPopUp(!popUp);
            }}
            style={{
              display: "flex",
              justifyContent: "end",
              background: "white",
            }}
          >
            <GrClose size={25} />
          </div>
          <Container>
            <div style={{ width: "70px", margin: "auto" }}>
              <img src={exceed} width="100%" />
            </div>
            <h3>You have exceeded your balance</h3>

            <p>Want to fund your Purse?</p>
            <Button
              color="white"
              backgroundcolor={isDisabled ? "#ffe1cc" : Colors.PrimaryColor}
              disabled={isDisabled}
              padding=".6rem"
              width="100%"
              type="submit"
              fontsize1="1rem"
              radius="2rem"
              shadow={`3px 0px 5px${Colors.BoxShadow}`}
              // disabled={isSubmitting}

              onClick={handleBackToFund}
              style={{ margin: "1rem 0", fontWeight: "500" }}
            >
              {loading ? <BtnLoader /> : "Buy New Notes"}
            </Button>
            <Button
              color="black"
              padding=".6rem"
              width="100%"
              type="submit"
              fontsize1=".9rem"
              radius="2rem"
              border={`1px solid ${Colors?.font1}`}
              shadow={`3px 0px 5px${Colors.BoxShadow}`}
              onClick={handleLeave}
              style={{ fontWeight: "600" }}
            >
              Leave Event
            </Button>
          </Container>
        </StyledModalContent>
      </StyledModalBackground>
    </>
  );
}

export default ExceededAmount;

const Container = styled.div`
  padding: 1rem;
  text-align: center;
  position: relative;

  h3 {
    margin: 0.6rem 0;
  }

  p {
    margin-top: 3rem;
  }
`;
