import React from "react";
import { styled } from "styled-components";
import { Colors } from "../Style/Colours";

function Progrees({ type }) {
  return (
    <Container>
      <aside>
        {type === "1" && (
          <>
            <div className="progress"></div>
            <div></div>
            <div></div>
            <div></div>
          </>
        )}
        {type === "2" && (
          <>
            <div className="progress"></div>
            <div className="progress"></div>
            <div></div>
            <div></div>
          </>
        )}
        {type === "3" && (
          <>
            <div className="progress"></div>
            <div className="progress"></div>
            <div className="progress"></div>
            <div></div>
          </>
        )}
        {type === "4" && (
          <>
            <div className="progress"></div>
            <div className="progress"></div>
            <div className="progress"></div>
            <div className="progress"></div>
          </>
        )}
      </aside>
    </Container>
  );
}

export default Progrees;

const Container = styled.section`
  aside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  div {
    width: 23%;
    height: 0.2rem;
    background: #d9d9d9;
    border-radius: 0.7rem;
  }

  .progress {
    background: ${Colors?.PrimaryColor};
  }
`;
