import React from "react";
import {
  Button,
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import { styled } from "styled-components";

function RedirectModal({ setReDirect }) {
  const handleSpray = () => {};

      // Get the total window height
      const totalHeight = window.outerHeight;

      // Get the viewport height
      const viewportHeight = window.innerHeight;
    
      // Calculate the header height
      const headerHeight = totalHeight - viewportHeight;
  return (
    <>
      <StyledModalBackground1 height={`cal(100vh - ${headerHeight}px)`}>
        <StyledModalContent
          maxwidth="80%"
          width="50%"
          padding="1rem 0"
          radius="1rem 1rem 0 0"
          MinHeight="35vh"
          // width="100%"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="400"
        >
          <Container>
            <aside>
              <div className="important">You're already a user</div>
              {/* <div className="text">
                Go to the app
              </div> */}

              <Button
                color="white"
                backgroundcolor={Colors.PrimaryColor}
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={handleSpray}
                style={{ margin: "3rem 0 .5rem 0", fontWeight: "600" }}
              >
                Go to the app
              </Button>
              <Button
                color="black"
                padding=".6rem"
                width="100%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                border={`1px solid ${Colors?.font1}`}
                shadow={`3px 0px 5px${Colors.BoxShadow}`}
                onClick={() => setReDirect(false)}
                style={{ fontWeight: "600" }}
              >
                Cancel
              </Button>
            </aside>
          </Container>
        </StyledModalContent>
      </StyledModalBackground1>
    </>
  );
}

export default RedirectModal;

const Container = styled.section`
  .important {
    color: #212121;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }

  .text {
    color: #626060;
    text-align: center;
    font-size: 0.9rem;
  }
`;
