import React, { useEffect, useState } from "react";
import {
  Button,
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
import styled from "styled-components";
import { LiaTimesSolid } from "react-icons/lia";
import BtnLoader from "../Reuseable/BtnLoader";
import Aos from "aos";
import axios from "axios";
import { secret } from "../Request/Secret";
import { toast } from "sonner";
import Modal from "./Modal";

function RequestFunds({ item, toggleParentModal }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [getAmountValue, setgetAmountValue] = useState("");

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  useEffect(() => {
    if (getAmountValue) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }, [getAmountValue]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  const requestData = {
    id: item?.id,
    secret,
    amount: Number(getAmountValue),
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/giftrequest/`,
        requestData,
        { headers }
      );

      if (response.data.status) {
        toast.success("", {
          description: response?.data?.message || "Sent",
          style: { color: "green" },
        });

        setLoading(false);
        setShowModal(false);
        // toggleParentModal(false)
        setgetAmountValue("");
      }
    } catch (error) {
      setLoading(false);
      setgetAmountValue("");
      toast.error("", {
        description:
          error?.response?.data?.message || "Opps!! something went wrong",
        style: { color: "red" },
      });
    }
  };

  // Get the total window height
  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <>
      <Button
        color={Colors.font3}
        backgroundcolor="#dedede"
        padding=".8rem"
        width="32%"
        type="submit"
        fontsize1=".8rem"
        radius="2rem"
        //   disabled={isDisabled}
        //   ref={buttonRef}
        onClick={() => setShowModal(true)}
        style={{ fontWeight: "600" }}
      >
        Request funds
      </Button>

      <Modal open={showModal}>
        <StyledModalBackground1 height={`cal(100vh - ${headerHeight}px)`}>
          <StyledModalContent
            padding="1rem 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
            style={{ zIndex: "90" }}
          >
            <Container>
              <div className="header">
                <div
                  style={{
                    width: "20%",
                    color: Colors.font3,
                    fontSize: "0.8rem",
                  }}
                ></div>
                <div
                  style={{ width: "60%", fontWeight: "600", margin: "auto" }}
                >
                  Request funds
                </div>
                <div
                  style={{ width: "20%" }}
                  className="Close"
                  onClick={() => {
                    setShowModal(false);
                    setgetAmountValue("");
                  }}
                >
                  <LiaTimesSolid size={30} />
                </div>
              </div>

              <div className="bodyDiv">
                <div>
                  <label htmlFor="name">Name</label>
                  <input type="text" readOnly value={item?.username} />
                </div>
                <div>
                  <label htmlFor="name">How much?</label>
                  <input
                    type="number"
                    onChange={(e) => setgetAmountValue(e?.target?.value)}
                    placeholder={`enter amount`}
                  />
                </div>

                <Button
                  color="white"
                  backgroundcolor={
                    isDisabled ||
                    getAmountValue === "" ||
                    parseFloat(getAmountValue) === 0
                      ? "#ffe1cc"
                      : Colors.PrimaryColor
                  }
                  disabled={
                    isDisabled ||
                    getAmountValue === "" ||
                    parseFloat(getAmountValue) === 0
                  }
                  padding=".8rem"
                  width="100%"
                  type="submit"
                  fontsize1=".9rem"
                  radius="2rem"
                  onClick={handleSubmit}
                  style={{ margin: "3rem 0 0 0", fontWeight: "600" }}
                >
                  {loading ? (
                    <BtnLoader />
                  ) : (
                    `Request ₦ ${formatNumber(getAmountValue || 0)}`
                  )}
                </Button>
              </div>
            </Container>
          </StyledModalContent>
        </StyledModalBackground1>
      </Modal>
    </>
  );
}

export default RequestFunds;

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 1.6rem 0 0.3rem 0;
  }

  .Close {
    color: ${Colors.font3};
    font-size: 0.9rem;
    cursor: pointer;
  }

  .bodyDiv {
    padding: 1rem;
  }

  .bodyDiv input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.8rem;
    border-radius: 0.3rem;
    outline: none;
    font-size: 1rem;
    margin: 0.3rem 0 1rem 0;

    &:read-only {
      background-color: #e8e8e8;
      color: #4a4a4a;
      cursor: not-allowed;
      border: none;
    }
    &::placeholder {
      color: #b6b6b6;
      font-size: 0.9rem;
    }
  }
  .bodyDiv label {
    font-size: 0.95rem;
    font-weight: 600;
    color: #4a4a4a;
  }
`;
