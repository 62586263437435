import React from 'react'
import { TypeAnimation } from 'react-type-animation';
import { Colors } from '../Style/Colours';

function TypeAnimations() {
  return (
    <>
      <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Welcome!', //[ Joining . ]
        1400, // wait 1s before replacing "Mice" with "Hamsters"
        'Joining The Party! ', //[ Joining . . ]
        1400,
        'Glad To Have You Here! ', //[ Joining . . . ]
        1400,
        'Party On!',
        1400,
        'Joining...',
        1400,
      ]}
      wrapper="span"
      speed={70}
      style={{ fontSize: '1.3rem', display: 'inline-block', fontFamily: 'boldFont', color: Colors.PrimaryColor }}
      repeat={Infinity}
    />
    </>
  )
}

export default TypeAnimations
