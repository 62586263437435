import React, { createContext, useState } from "react";

export const CodeContext = createContext();

function CodeContextProvider1({ children }) {
  const [code, setCode] = useState("");
  const updateCode = (newCode) => {
    setCode(newCode);
  };

  const [ifClickedOnLeave, setIfClickedOnLeave] = useState(true);

  return (
    <CodeContext.Provider
      value={{ code, updateCode, setIfClickedOnLeave, ifClickedOnLeave }}
    >
      {children}
    </CodeContext.Provider>
  );
}

export default CodeContextProvider1;
