import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
// import single from "../assets/currencency/single.svg";
import bundle from "../assets/currencency/bundle.svg";
import background from "../assets/imgs/liveBack.svg";
import wallet from "../assets/imgs/wallet.png";
import walletJif from "../assets/imgs/wallet.gif";
import liveLogo from "../assets/live/dpparty.jpeg";

import swipe from "../assets/imgs/swipe.svg";

// single money
import single100 from "../assets/live/singleSpray/h100.png";
import single200 from "../assets/live/singleSpray/h200.png";
import single500 from "../assets/live/singleSpray/h500.png";
import single1000 from "../assets/live/singleSpray/h1000.png";

// bundle money straigth
import bundle100 from "../assets/live/bundles/bundle100.png";
import bundle200 from "../assets/live/bundles/bundle200.png";
import bundle500 from "../assets/live/bundles/bundle500.png";
import bundle1000 from "../assets/live/bundles/bundle1000.png";

// bundle2 money bend
import bundle2100 from "../assets/live/Bundles2/bundles2100.png";
import bundle2200 from "../assets/live/Bundles2/bundles2200.png";
import bundle2500 from "../assets/live/Bundles2/bundles2500.png";
import bundle21000 from "../assets/live/Bundles2/bundles21000.png";

import useWebSocket from "react-use-websocket";

import Aos from "aos";
import ExceededAmount from "../Modals/ExceededAmount";
import ThankYouModal from "../Modals/ThankYouModal";
import baseURL from "../Request/BaseUrl";
import { post } from "../Request/GetAPI";
import { secret } from "../Request/Secret";
import SelectCuurency from "../Modals/SelectCuurency";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
// import { motion } from "framer-motion";
import Ranking from "../Modals/Ranking";
import LiveVideo from "../assets/AnimatedSVG/LiveVideo.mp4";
import displayVideo from "../assets/live/videoD.mp4";

import LazyImage from "../Reuseable/LazyImage";
import axios from "axios";
import { toast } from "sonner";
import TypeAnimations from "../Animations/TypeAnimation";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import FundWallet from "../Modals/FundWallet";
import BuyMoneyModal from "../Modals/BuyMoneyModal";
import RequestMessagesSocket from "../Sockets/RequestMessagesSocket";
import { Colors } from "../Style/Colours";
import SelectDinominationModal from "../Modals/PopUpAtFirstModal/SelectDinominationModal";
import BuyFundsPopUp from "../Modals/PopUpAtFirstModal/BuyFundsPopUp";
import { FetchBalanceContext } from "../Context/FetchBalanceProvider";
import bubbleGif from "../assets/live/buble.gif";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";

const userDetails = JSON.parse(sessionStorage?.getItem("details"));

const backGroundImg = (userDetails && userDetails?.eventImg) || background;

function VideoLiveStram() {
  const getCode = sessionStorage.getItem("code");
  const getDisplayName = sessionStorage.getItem("displayName");
  let name = sessionStorage.getItem("roomname");
  const [initialized, setInitialized] = useState(false);
  const [jitsiLoading, setJitsiLoading] = useState(true);

  const { width: screenWidth, height: screenHeight } = useWindowSize();

  const session = useRef(null);

  // const [failedMoney, setFailedMoney] = useState(0);

  // document.addEventListener("DOMContentLoaded", () => {
  //   const resizeMainContent = () => {
  //     const headerHeight = document.querySelector(".header")?.offsetHeight || 0;
  //     const mainContent = document.querySelector(".main-content");
  //     if (mainContent) {
  //       mainContent.style.height = `calc(100vh - ${headerHeight}px)`;
  //     }
  //   };

  //   // Call resizeMainContent initially to set the correct height
  //   resizeMainContent();

  //   // Add event listener for window resize
  //   window.addEventListener("resize", resizeMainContent);
  // });

  //number formatter
  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  //for animations
  const [animate, setAnimate] = useState(true);
  const [replace, setReplace] = useState(true);
  const [balanceAni, setBalanceAni] = useState(false);
  const [checkNote, setcheckNote] = useState(false);
  const [checkNote2, setcheckNote2] = useState(false);
  const [checkCurrency, setCheckCurrency] = useState(false);
  const checkRemainBalance = useRef(false);
  const [BuyNewNoteModal, setBuyNewNoteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkIfSprayedTo1000K, setCheckIfSprayedTo1000K] = useState(false);

  //get sessionStorage
  const note = parseFloat(sessionStorage.getItem("curr2")) || 0; // Parse float or default to 0
  const typeQuantity = sessionStorage?.getItem("single");

  const [showModalFirst, setShowModalFirst] = useState(true);

  const { showBubblePopUpWhenSprayedOtherGuest } = useContext(OpenFundsModal);

  const {
    show,
    fetchBalance,
    amountRef,
    balanceRef,
    popUp,
    setPopUp,
    fetchBalanceOnlyWhenBalanceIs0,
  } = useContext(FetchBalanceContext);

  console.log(show);

  // State for tracking the drag position
  const constraintsRef = useRef(null);

  useEffect(() => {
    if (amountRef.current > 0) {
      setShowModalFirst(true);
      // if (
      //   !sessionStorage.getItem("curr") ||
      //   !sessionStorage.getItem("curr2") ||
      //   !sessionStorage.getItem("single")
      // ) {
      //   sessionStorage.setItem("curr", "100 Note");
      //   sessionStorage.setItem("curr2", "100");
      //   sessionStorage.setItem("single", "Single notes");
      // }
    }
    if (amountRef.current <= 0) {
      setShowModalFirst(false);
    }
  }, []);

  // map single money
  const noteMappings = {
    1000: single1000,
    500: single500,
    200: single200,
    100: single100,
  };
  const sideSingle = noteMappings[note] || null;

  //map bundle money
  const bundlenoteMappings = {
    1000: bundle1000,
    500: bundle500,
    200: bundle200,
    100: bundle100,
  };
  const sideBundle = bundlenoteMappings[note] || null;

  //map bundle money
  const bundle2noteMappings = {
    1000: bundle21000,
    500: bundle2500,
    200: bundle2200,
    100: bundle2100,
  };
  const sideBundle2 = bundle2noteMappings[note] || null;

  const endpoint = "/guestapi/spray/";
  const headers = {
    "Content-Type": "application/json",
  };

  const MoneyValue =
    typeQuantity === "Bundle of cash" && amountRef.current <= note * 100
      ? amountRef.current
      : typeQuantity === "Bundle of cash" && amountRef.current > note * 100
      ? note * 100
      : typeQuantity === "Single notes" && amountRef.current <= note
      ? amountRef.current
      : typeQuantity === "Single notes" && amountRef.current > note
      ? note
      : 0;

  // WebSocket connection setup
  // const socketUrl = `wss://backend.partytime.ng/ws/spray/${
  //   getCode !== null && getCode !== "null" ? getCode : userDetails?.code
  // }/`;
  // const socket = new ReconnectingWebSocket(socketUrl, [], {
  //   WebSocket,
  //   minReconnectionDelay: 200,
  //   maxReconnectionDelay: 500,
  //   reconnectionDelayGrowFactor: 1,
  //   maxRetries: 4,
  // });

  // // Handle WebSocket events
  // socket.addEventListener("open", (event) => {
  //   console.log("WebSocket connected");
  // });

  // socket.addEventListener("message", (event) => {
  //   const receivedMessage = JSON.parse(event.data).message;
  //   console.log(receivedMessage);
  // });

  // socket.addEventListener("close", (event) => {
  //   console.log("WebSocket closed:", event.code, event.reason);
  // });

  // socket.addEventListener("error", (event) => {
  //   console.error("WebSocket error:", event);
  // });

  const socketUrl = `wss://backend.partytime.ng/ws/spray/${
    getCode !== null && getCode !== "null" ? getCode : userDetails?.code
  }/`;
  // ${
  //   getCode !== null && getCode !== "null" ? getCode : userDetails?.code
  // }
  // /`;

  // 831455

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  // Modify the handleSwipe function
  const handleSwipe = () => {
    const handleSprayPerClick = async () => {
      const data = JSON.stringify({
        data: {
          Note: MoneyValue,
          secret,
        },
      });

      try {
        const response = await post(baseURL, endpoint, data, headers);

        if (response.status) {
          fetchBalance();
          // const message = `${typeQuantity === "Bundle of cash" ? note * 100 : note}`;
          // socket.send(JSON.stringify({ message }));
          const messageObj = {
            message: typeQuantity === "Bundle of cash" ? note * 100 : note,
          };

          // const message = JSON.stringify(messageObj);
          // socket.send(message);

          // Call sendMessage wherever you need to send a message
          sendJsonMessage(messageObj);
        }
      } catch (error) {
        fetchBalance();
      }
    };

    if (guestInfo === "") {
      handleSprayPerClick();
    } else {
      handleSprayOtherUserBalance();
    }

    setAnimate(false);
    setTimeout(() => {
      setReplace(false);
      setBalanceAni(false);
    }, 200);

    setTimeout(() => {
      setAnimate(true);
      setReplace(true);
      setBalanceAni(true);
    }, 600);

    checkBalance();

    // balanceAni.current += 10000;
  };

  const checkBalance = () => {
    if (note <= amountRef.current) {
      if (typeQuantity === "Bundle of cash") {
        if (checkNote) {
          // If balance is not enough for the bundle of cash, add balance to amount and set show to false
          balanceRef.current += amountRef.current;
          amountRef.current = 0;
          // setShow(false);
        } else {
          amountRef.current -= note * 100;
          balanceRef.current += note * 100;
          // setShow(true);
        }
      } else {
        if (checkNote2) {
          // If balance is not enough for the regular scenario, add balance to amount and set show to false
          balanceRef.current += amountRef.current;
          amountRef.current = 0;
          // setShow(false);
        } else {
          amountRef.current -= note;
          balanceRef.current += note;
          // setShow(true);
        }

        localStorage.setItem("a1mount2", amountRef.current);
        // console.log(amountRef.current);
        sessionStorage.setItem("balance", balanceRef.current);
      }
    } else {
      // setShow(false);
    }
  };

  useEffect(() => {
    if (amountRef.current === 0) {
      // setShow(false);
      localStorage.removeItem("a1mount2");
    }
    if (amountRef.current < note * 100 && typeQuantity === "Bundle of cash") {
      setcheckNote(true);
    }
    if (amountRef.current < note && typeQuantity === "Single notes") {
      setcheckNote2(true);
    }

    // If amountRef.current is less than note, add it to balanceRef.current
    if (amountRef.current < note) {
      balanceRef.current += amountRef.current;
      amountRef.current = 0;
      // setShow(false);
      // setPopUp(true);
      // localStorage.removeItem("a1mount2");
      // handleSpray();
    }
  }, [amountRef.current]);

  //for live streams
  useEffect(() => {
    let script = document.createElement("script");
    script.src = "https://media.partytime.ng/external_api.js";
    script.async = true;
    script.onload = initializeJitsi;
    document.body.appendChild(script);

    return () => {
      if (session.current) {
        // Disconnect from the Jitsi video call
        session.current.disconnect();
      }

      // Other cleanup actions
      // ...

      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const navigate = useNavigate();

  const handleLeave = () => {
    if (getCode && getCode.code) {
      // sessionStorage.removeItem("balance");
      sessionStorage.removeItem("curr");
      sessionStorage.removeItem("curr2");
      sessionStorage.removeItem("details");
      sessionStorage.removeItem("roomname");
      // localStorage.removeItem("a1mount2");
      sessionStorage.removeItem("money");
      navigate(`/?code=${getCode.code}`);
    }
  };

  const handleLeaveWhenEventIsClosed = () => {
    const endpoint = "/guestapi/spray/";
    const headers = {
      "Content-Type": "application/json",
    };

    const handleSpray = async () => {
      const data = JSON.stringify({
        data: {
          Note: amountRef.current,
          secret,
        },
      });

      try {
        const response = await post(baseURL, endpoint, data, headers);

        if (response.status) {
        } else {
        }
      } catch (error) {}
    };

    handleSpray();

    if (getCode && getCode.code) {
      sessionStorage.removeItem("balance");
      sessionStorage.removeItem("curr");
      sessionStorage.removeItem("curr2");
      sessionStorage.removeItem("details");
      sessionStorage.removeItem("roomname");
      localStorage.removeItem("a1mount2");
      sessionStorage.removeItem("money");
      navigate(`/?code=${getCode.code}`);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.status === "Closed") {
      handleLeaveWhenEventIsClosed();
    }
  }, [userDetails?.status]);

  const initializeJitsi = () => {
    if (initialized) {
      // setIsLoading(true)
      return;
    }

    const domain = "media.partytime.ng";

    const options = {
      roomName: name,
      width: "100%",
      height: "100%",
      parentNode: document.querySelector("#meet"),
      lang: "en",
      configOverwrite: {
        startWithAudioMuted: false,
        startWithVideoMuted: false,
        prejoinPageEnabled: false,
        enableLobbyChat: true,
        disableInitialGUM: true,
        disableDeepLinking: true,
        preferH264: true,
      },
      userInfo: {
        displayName: getDisplayName,
      },
      interfaceConfigOverwrite: {
        SHOW_CHROME_EXTENSION_BANNER: false,
        TOOLBAR_ALWAYS_VISIBLE: false,
        SETTINGS_SECTIONS: ["devices", "language"],
        SHOW_POWERED_BY: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        HIDDEN_DEEP_LINKING_LOGO: true,
        DEFAULT_LOGO_URL: "",
        DEFAULT_WELCOME_PAGE_LOGO_URL: "",
        JITSI_WATERMARK_LINK: "",
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DEFAULT_REMOTE_DISPLAY_NAME: "Vertical View",
        DEFAULT_LOCAL_DISPLAY_NAME: "You",
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_NOTIFICATIONS: true,
        DISABLE_POPUP_NOTIFICATIONS: true,
        DISABLE_VIDEO_BACKGROUND: true,
        TOOLBAR_BUTTONS: [
          "camera",
          "microphone",
          "raisehand",
          // "desktop",
          // "fullscreen",
          "videobackgroundblur",
          // "tileview",
          // "profile",
          "chat",
        ],
      },
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);
    api.on("videoConferenceJoined", () => {
      // Set jitsiLoading to false when the conference is joined
      setJitsiLoading(false);
      setIsLoading(false);
      // console.log("finished", isLoading);
    });
    setIsLoading(true);
    // console.log("still", isLoading);
    setInitialized(true);
  };

  // const confirmLeave = (event) => {
  //   const message = "Are you sure you want to leave?";
  //   const result = window.confirm(message);
  //   handleLeave();

  //   if (result) {
  //   } else {
  //     // If the user clicks 'Cancel'
  //     event.preventDefault();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", confirmLeave);

  //   return () => {
  //     window.removeEventListener("beforeunload", confirmLeave);
  //   };
  // }, []);

  useEffect(() => {
    Aos.init({
      duration: 1500,
      easing: "ease-in-out",
    });
  }, []);

  const lazyImagePropssideBundle = {
    // caption: "Image Caption",
    src: sideBundle,
    width: "100%",
  };
  const lazyImagePropsSideBundle2 = {
    src: sideBundle2,
    width: "100%",
  };

  const [guestImGet, setGuestImGet] = useState(
    userDetails?.hostImg || liveLogo
  );

  const [guestInfo, setGuestImgInfo] = useState("");

  useEffect(() => {
    const updateGuestImg = () => {
      if (guestInfo) {
        setGuestImGet(guestInfo);
      } else {
        setGuestImGet(userDetails?.hostImg || liveLogo);
      }
    };

    // Update guestImGet on component mount
    updateGuestImg();

    // Add event listener for storage changes
    window.addEventListener("storage", updateGuestImg);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("storage", updateGuestImg);
    };
  }, [userDetails, liveLogo, guestInfo]);

  const lazyImagePropsHostImg = {
    src:
      guestInfo?.avatar ||
      (sessionStorage.getItem("guestImg") &&
        JSON?.parse(sessionStorage.getItem("guestImg"))?.avatar) ||
      guestImGet,
    // src: sessionStorage.getItem("guestImg") ? guestInfo?.avatar || JSON?.parse(sessionStorage.getItem("guestImg"))?.avatar : guestImGet,
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    // Check if the item is not available in localStorage before calling fetchBalance
    if (localStorage.getItem("a1mount2") && sessionStorage?.getItem("money")) {
      sessionStorage?.removeItem("money");
      amountRef.current = localStorage.getItem("a1mount2");
      fetchBalance();
    }
  }, []);

  useEffect(() => {
    // Function to check milestones
    const checkMilestone = () => {
      if (balanceRef.current % 100000 === 0 && balanceRef.current !== 0) {
        setCheckIfSprayedTo1000K(true);
        toast.success("", {
          description: `₦ ${formatNumber(
            balanceRef.current
          )} Spray Milestone Unlocked! 🎉🥳 `,
          style: { color: "green" },
        });
        setTimeout(() => {
          toast.success("", {
            description: `You're the life of the party! 🚀✨`,
            style: { color: "green" },
          });
        }, 4000);
      } else {
        setCheckIfSprayedTo1000K(false);
      }
    };

    // Call the checkMilestone function when balanceRef.current changes
    checkMilestone();
  }, [balanceRef.current]);

  useEffect(() => {
    if (isLoading === false) {
      if (checkRemainBalance.current === true) {
        // setCheckCurrency(true);
      } else {
        // setBuyNewNoteModal(true);
      }
    }
  }, []);

  // Get the total window height
  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  // Function to increase yourVariable
  // const increaseVariable = () => {
  //   yourVariableRef.current += 10000;
  // };

  //   function isPastCloseDate(closeDate) {
  //     // Convert the closeDate string to a Date object
  //     const closeDateTime = new Date(closeDate);

  //     // Get the current local time
  //     const currentDateTime = new Date();
  //     console.log(closeDateTime, currentDateTime);

  //     // Compare the closeDate with the current time
  //     return closeDateTime < currentDateTime;
  //   }

  //   // Example usage:
  //   const closeDate = "2023-12-28T01:02:00+01:00";
  //   const isPast = isPastCloseDate(closeDate);
  //   const closeDateTime = new Date();
  // const closeDateTimeString = closeDateTime.toISOString();

  // console.log("Close Date Time as String:", closeDateTimeString);

  //   if (isPast) {
  //     console.log("The close date has passed.");
  //   } else {
  //     console.log("The close date is in the future.");
  //   }

  console.log(guestInfo);

  const [otherUserBalance, setOtherUserBalance] = useState({
    data: null,
    loading: false,
  });

  //?receiver=2&id=1

  const fetchOtherUserBalance = async () => {
    setOtherUserBalance((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await axios.get(
        `${baseURL}/guestapi/checksprayed/?receiver=${guestInfo?.id}&id=${secret}`,
        {
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
        }
      );

      if (response.status === 200) {
        setOtherUserBalance({
          data: response?.data?.data?.sprayed,
          loading: false,
        });

        //  if (response?.data?.data?.balance > 0) {
        //   console.log('greater', response?.data?.data?.balance);
        //   show.current = true;
        //   setPopUp(false)
        // } else {
        //   console.log('lesser');
        //   show.current = false;
        //   setPopUp(true)
        // }
      } else {
        setOtherUserBalance((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      setOtherUserBalance((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleSprayOtherUserBalance = async () => {
    const data = JSON.stringify({
      amount: MoneyValue,
      secret,
      reciever: guestInfo?.id,
    });

    try {
      const response = await post(
        baseURL,
        "/guestapi/sprayOthers/",
        data,
        headers
      );

      if (response.status) {
        // setOtherUserBalance({
        //   ...otherUserBalance,
        //   loading: false,
        // });

        fetchOtherUserBalance();
        fetchBalanceOnlyWhenBalanceIs0();
      }
    } catch (error) {
      // setOtherUserBalance({
      //   ...otherUserBalance,
      //   loading: false,
      // });
    }
  };

  useEffect(() => {
    if (guestInfo !== "") {
      fetchOtherUserBalance();
    }
  }, [guestInfo]);

  return (
    <>
      <Container style={{ height: `cal(100vh - ${headerHeight}px)` }}>
        {checkIfSprayedTo1000K && (
          <Confetti width={screenWidth} height={screenHeight} />
        )}
        {isLoading && (
          <div className={`loading-animation ${isLoading ? "show" : ""}`}>
            <div className="typeani">
              <TypeAnimations />
            </div>
            <video
              width="580"
              height="320"
              autoPlay
              loop
              muted
              playsinline
              style={{ mixBlendMode: "color-burn11" }}
            >
              <source src={LiveVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {showBubblePopUpWhenSprayedOtherGuest && (
          <div
            className={`bubble-animation ${
              showBubblePopUpWhenSprayedOtherGuest ? "show" : ""
            }`}
          >
            {/* <video
      width="600"
      height="320"
      autoPlay
      loop
      muted
      style={{ mixBlendMode: "multiply" }}
    >
      <source src={bubbleGif} type="video/mp4" />
      Your browser does not support the video tag.
    </video> */}

            <img
              src={bubbleGif}
              alt="Bubble Animation"
              width="100%"
              height="100%"
              style={{ mixBlendMode: "multiply" }}
            />
          </div>
        )}

        <div style={{ padding: ".8rem" }}>
          <PageContainer>
            <aside className="wallet">
              <div className="walletDiv">
                <img src={wallet} alt="wallet" />
              </div>
              <div className="money">Money Sprayed</div>
              {/* checkIfSprayedTo1000K */}
              <div
                className={` ${
                  !balanceAni
                    ? "nairaAnimation"
                    : !checkIfSprayedTo1000K
                    ? "naira"
                    : "upto100k"
                }`}
              >
                ₦{" "}
                {formatNumber(
                  guestInfo !== ""
                    ? otherUserBalance?.data === null ||
                      otherUserBalance?.data === "null"
                      ? 0
                      : otherUserBalance?.data
                    : balanceRef.current
                )}
              </div>
            </aside>
            <aside className="Host">
              <div style={{ paddingBottom: ".3rem" }}>
                {guestInfo?.username ||
                  (sessionStorage.getItem("guestImg") &&
                    JSON?.parse(sessionStorage.getItem("guestImg"))
                      ?.username) ||
                  "Host"}{" "}
              </div>

              {/* <FundOrRequestSocket /> */}
              <RequestMessagesSocket />
              <div className="hostImg">
                {/* <img
                  src={userDetails?.hostImg || liveLogo}
                  alt=""
                  width="100%"
                /> */}
                <LazyImage image={lazyImagePropsHostImg} />
                {/* {
                  <div>
                    <video
                      width="100"
                      height="100"
                      autoPlay
                      loop
                      muted
                      style={{ borderRadius: '100%', objectFit: 'contain' }}
                    >
                      <source src={displayVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                } */}
              </div>
            </aside>
            <aside className="cancel1all">
              <div>
                <ThankYouModal data={otherUserBalance} />
              </div>
              <div>
                <Ranking />
              </div>
            </aside>
          </PageContainer>
        </div>
        <MeetContainer id="meet"></MeetContainer>

        <SprayContainer className="spraycont">
          {/* {show?.current  ? 'show' : 'off'} */}
          {show?.current === true && (
            <>
              <div className="stack">
                <LazyImage image={lazyImagePropsSideBundle2} />
              </div>
              {sessionStorage?.getItem("single") === "Bundle of cash" ? (
                <>
                  <div className={`${replace ? "swipe3" : "swipe3s"}`}>
                    <LazyImage image={lazyImagePropssideBundle} />
                  </div>
                  <div
                    className={`swipe ${animate ? "swipe" : "swipes"}`}
                    onClick={handleSwipe}
                  >
                    <LazyImage image={lazyImagePropssideBundle} />
                  </div>
                  <div className="swipe233" onClick={handleSwipe}>
                    <LazyImage image={lazyImagePropssideBundle} />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${replace ? "swipe3" : "swipe3s"}`}>
                    <img src={sideSingle} alt="" width="100%" />
                  </div>
                  <div
                    className={`${animate ? "swipe" : "swipes"}`}
                    onClick={handleSwipe}
                  >
                    <img src={sideSingle} alt="" width="100%" />
                  </div>
                  <div className="swipe2" onClick={handleSwipe}>
                    <img src={sideSingle} alt="" width="100%" />
                  </div>
                </>
              )}
            </>
          )}

          <div
            className="up"
            // onClick={() => {
            //   setCheckCurrency(true);
            // }}
            // style={{ boxShadow: "0px 0px 10px #777777" }}
          >
            {/* storedMoney */}
            {amountRef.current !== undefined &&
              (amountRef.current === 0 || amountRef.current < 0) &&
              !isLoading && (
                <>
                  <BuyMoneyModal
                    showModal={BuyNewNoteModal}
                    setShowModal={setBuyNewNoteModal}
                  />
                  {/* <BuyFundsPopUp /> */}
                </>
              )}

            {amountRef.current !== undefined &&
              amountRef.current > 0 &&
              !isLoading && (
                <>
                  <SelectCuurency
                    checkCurrency={checkCurrency}
                    setCheckCurrency={setCheckCurrency}
                  />
                  {/* <SelectDinominationModal /> */}
                </>
              )}

            {!isLoading && showModalFirst && <SelectDinominationModal />}
            {!isLoading && !showModalFirst && <BuyFundsPopUp />}

            {/* <BuyMoneyModal
              showModal={BuyNewNoteModal}
              setShowModal={setBuyNewNoteModal}
            /> */}
            {/* <SelectCuurency setCheckCurrency={setCheckCurrency} /> */}
            {/* <MdKeyboardDoubleArrowUp /> */}
          </div>
          <div className="GuestModal">
            <FundWallet Img={guestInfo} setImg={setGuestImgInfo} />
          </div>
        </SprayContainer>

        <>
          {popUp && !isLoading && (
            <ExceededAmount popUp={popUp} setPopUp={setPopUp} />
          )}
        </>
        <>
          {/* {checkRemainBalance && !isLoading && amountRef.current > 0 && (
            <SelectCuurency setCheckCurrency={setCheckCurrency} />
          )}
          {!checkRemainBalance && !isLoading && amountRef.current <= 0 && (
            <BuyMoneyModal
              showModal={BuyNewNoteModal}
              setShowModal={setBuyNewNoteModal}
            />
          )} */}
        </>
      </Container>
    </>
  );
}

export default VideoLiveStram;

const Container = styled.div`
  background-image: url(${backGroundImg});
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #343434;
  /* height: 100vh;
  height: 100svh; */
  position: fixed;
  /* overflow: hidden; */
  width: 100vw;
  transition: opacity 0.5s ease-in-out;

  // @media screen and (max-width: 460px) {
  // }

  .walletDiv {
    width: 40px;
    padding: 0.5rem 0;
    margin: auto;
  }
  .walletDiv img {
    width: 100%;
  }

  .loading-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(5px); /* make it blur */

    background: #fff; /* Adjust background color as needed */
    z-index: 1000; /* Adjust the z-index to be above other content */
  }

  .typeani {
    font-family: pestaFont;
  }

  .loading-animation.show {
    opacity: 0.8;
    z-index: 9;
  }

  .bubble-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000; /* Adjust the z-index to be above other content */
    background: none; /* Ensure no background color */
  }

  .bubble-animation.show {
    opacity: 1;
    z-index: 9;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20vh;
  height: 20svh;

  .wallet {
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10.275229454040527px);
    padding: 0 0.4rem 0.4rem 0.4rem;
    // height: fit-content;
  }

  .money {
    /* color: #6f6f6f; */
    color: #fff;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0.3rem 0;
  }

  .Host {
    text-align: center;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  .hostImg {
    width: 100px;
    height: 100px;
  }

  .cancel1all {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-evenly;
  }

  .naira {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #f08f4f;

    position: relative;
    bottom: 0%; /* Start at the bottom */
    transition: bottom 0.3s ease;
  }

  .nairaAnimation {
    position: relative;
    color: #f08f4f;

    animation-name: naira;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  @keyframes naira {
    0% {
      bottom: -20%;
      font-size: 0.5rem;
    }
    100% {
      bottom: 0%;
    }
  }
  .upto100k {
    position: relative;
    color: #ff6700;
    animation-name: upto100k;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    backface-visibility: hidden; /* Add this line */
  }

  @keyframes upto100k {
    0% {
      transform: scale(1.01);
      font-size: 0.7rem;
    }
    100% {
      transform: scale(1.21);
    }
  }

  // @media (max-width: 430px) {
  //   height: 15vh;
  //   height: 15svh;
  // }
  // @media (max-width: 380px) {
  //   height: 18vh;
  //   height: 18svh;
  // }

  /* @media (min-width: 385px) and (max-width: 442px) {
 background-color: red;
} */
`;

const MeetContainer = styled.div`
  width: 100%;
  height: 60vh;
  height: 60svh;
  z-index: 10;

  // @media (max-width: 400px) {
  //   height: 55vh;
  //   height: 55svh;
  // }
`;

const SprayContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 20vh;
  height: 20svh;

  .up {
    position: absolute;
    right: 4.5%;
    top: 3%;

    @media screen and (min-width: 400px) {
      top: 5%;
    }
  }
  .GuestModal {
    position: absolute;
    right: 4.5%;
    border-radius: 50%;
    bottom: 20%;
    box-shadow: 0px 0px 10px #414141;
    padding: 0.4rem 0.6rem;
    cursor: pointer;
    color: ${Colors?.PrimaryColor};
    background-color: rgba(256, 256, 256, 0.5);
  }

  .swipe3 {
    position: absolute;
    left: -14%;
    width: 65px;
    transform: rotate(-85deg);
    // bottom: -80%;
    opacity: 0;
  }
  .swipe2 {
    position: absolute;
    left: 54%;
    width: 65px;
    transform: translateX(-50%);
    bottom: 19%;
  }
  .swipe233 {
    position: absolute;
    left: 54%;
    width: 55px;
    transform: translateX(-50%);
    bottom: 19%;
  }

  .swipe {
    position: absolute;
    left: 50%;
    width: 65px;
    transform: translateX(-50%);
    bottom: 23%;
    z-index: 5;
    cursor: pointer;
  }
  .swipes {
    position: absolute;
    left: 50%;
    width: 65px;
    transform: translateX(-50%);
    /* bottom: 0%; */
    transition: linear 0.5s;

    animation-name: moveUp;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .swipe3s {
    position: absolute;
    left: -14%;
    width: 55px;
    transform: rotate(-85deg);
    bottom: -80%;

    animation-name: swipe3s;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  @keyframes swipe3s {
    0% {
      left: 20%;
      bottom: 10%;
    }
    40% {
      opacity: 0.7;
    }
    70% {
      opacity: 0.7;
    }
    80% {
      opacity: 0.5;
    }

    100% {
      left: 50%;
      opacity: 0.2;
      bottom: 20%;
    }
  }

  @keyframes moveUp {
    0% {
      bottom: 15%;
    }
    70% {
      opacity: 0.7;
    }
    80% {
      opacity: 0.5;
    }

    100% {
      bottom: 300%;
      opacity: 0.2;
    }
  }

  .stack {
    position: absolute;
    width: 120px;
    bottom: 20%;
    z-index: 3;
  }

  /* @media (max-width: 400px) {
      background-color: red;
  } */
`;
