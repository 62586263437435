import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import EventDetails from "./componets/EventDetails";
import Paystack from "./componets/Paystack";
import Spray from "./componets/Spray";
import LandingPage from "./componets/LandingPage";
import SuccessfulTransaction from "./Modals/SuccessfulTransaction";
import FailedTransaction from "./Modals/FailedTransaction";
import Amount from "./componets/Amount";
import VideoLiveStram from "./componets/VideoLiveStram";
// import PermissionHandler from "./Reuseable/PermissionHandler";
// import AppLogout from "./Hooks/AppLogout";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route index element={<LandingPage />} />
        <Route path="/event" element={<EventDetails />} />
        <Route path="/payment" element={<Paystack />} />
        <Route path="/spray" element={<Spray />} />
        <Route path="/successtransaction" element={<SuccessfulTransaction />} />
        <Route path="/failedtransaction" element={<FailedTransaction />} />
        <Route path="/amount" element={<Amount />} />
        <Route
          path="/live"
          element={
            // <AppLogout>
              <VideoLiveStram />
            // </AppLogout> 
          }
        />
      </Route>
    )
  );
  return (
    <div className="App">
      {/* <PermissionHandler /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
