import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { PuffLoader } from "react-spinners";
import { StyledModalLoader } from "./StyledComponents";
import { Colors } from "../Style/Colours";
import styled from "styled-components";

function PageLoader() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate a delay for the animation
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    // You can adjust the duration based on your desired animation time.
  }, []);
  return (
    <Container>
      <div
      className={isLoaded ? 'show' : 'showno'}
      >
        <StyledModalLoader>
          <PuffLoader color={Colors.PrimaryColor} size={130} />
        </StyledModalLoader>
      </div>
    </Container>
  );
}

export default PageLoader;

const Container = styled.div`
  .showno {
    opacity: 0;
  }

  .show {
    transition: linear 0.4s;
    opacity: 1;
  }
`;
