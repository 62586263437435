import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  DateInput,
  StyledModalBackground1,
  StyledModalContent,
} from "../../Reuseable/StyledComponents";
import baseURL from "../../Request/BaseUrl";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import BtnLoader from "../../Reuseable/BtnLoader";
import { RiDeleteBack2Line } from "react-icons/ri";
import { FaAngleDoubleDown } from "react-icons/fa";

import Aos from "aos";

// import bundle100 from "../../assets/live/bundles/bundle100.png";
// import bundle200 from "../../assets/live/bundles/bundle200.png";
// import bundle500 from "../../assets/live/bundles/bundle500.png";
// import bundle1000 from "../../assets/live/bundles/bundle1000.png";
import styled from "styled-components";
import { Colors } from "../../Style/Colours";
import { post, put } from "../../Request/GetAPI";
import { secret } from "../../Request/Secret";
import { OpenFundsModal } from "../../Context/OpenFundsModalsProvider";

import bundle100 from "../../assets/amountPage/bundleM/Bundle100.png";
import bundle200 from "../../assets/amountPage/bundleM/Bundle200.png";
import bundle500 from "../../assets/amountPage/bundleM/Bundle500.png";
import bundle1000 from "../../assets/amountPage/bundleM/Bundle1000.png";

import backG100 from "../../assets/amountPage/backg100.png";
import backG200 from "../../assets/amountPage/backg200.png";
import backG500 from "../../assets/amountPage/backg500.png";
import backG1000 from "../../assets/amountPage/backg1000.png";
import { FaCircleCheck } from "react-icons/fa6";
import { BsInfoCircleFill } from "react-icons/bs";
import { FetchBalanceContext } from "../../Context/FetchBalanceProvider";

function BuyFundsPopUp() {
  const [inputValue, setInputValue] = useState("");
  const { buyFundsPopUp, setBuyFundsPopUp, clearSessionStorageForOrigin } =
    useContext(OpenFundsModal);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [disable, setdisable] = useState(true);
  const [singleValue, setSingleValue] = useState(null);
  const [moneyValue, setMoneyValue] = useState(null);
  const [showNumericKeypad, setShowNumericKeypad] = useState(false);
  // const [showImporModal, setShowImporModal] = useState(false);

  const [getCash, setGetCash] = useState({
    amount: "",
    charge: "",
  });

  console.log('fundFirst');

  // const { isopen, setIsopen } = useContext(ModalContext);
  const moneyArr = [
    {
      amount: "10000",
      label: "100 note",
      label1: "10,000",
      img: bundle100,
      backG: backG100,
      clickBackG: backG100,
      backColor: "#FDF2E9",
      clickedBackColor: "#FDF2E9",
      id: 1,
      count: 1,
      color: Colors?.PrimaryColor,
    },
    {
      amount: "20000",
      label: "200 note",
      label1: "20,000",
      img: bundle200,
      backG: backG200,
      clickBackG: backG100,
      backColor: "#FBE8ED",
      clickedBackColor: "#FDF2E9",
      id: 2,
      count: 1,
      color: "#EE3198",
    },
    {
      amount: "50000",
      label: "500 note",
      label1: "50,000",
      img: bundle500,
      backG: backG500,
      clickBackG: backG100,
      backColor: "#E8FCF7",
      clickedBackColor: "#FDF2E9",
      id: 3,
      count: 1,
      color: "#458C3A",
    },
    {
      amount: "100000",
      label: "1000 note",
      label1: "100,000",
      img: bundle1000,
      backG: backG1000,
      clickBackG: backG100,
      backColor: "#E6F1FE",
      clickedBackColor: "#FDF2E9",
      id: 4,
      count: 1,
      color: "#1A7BF1",
    },
    // {
    //   amount: "500000",
    //   label: "500 Note",
    //   label1: "500,000",
    //   img: bundle500,
    //   id: 5,
    //   count: 1,
    // },
    // {
    //   amount: "1000000",
    //   label: "1,000 Note",
    //   label1: "1,000,000",
    //   img: bundle1000,
    //   id: 6,
    //   count: 1,
    // },
  ];

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate a delay for the animation
    setTimeout(() => {
      setIsLoaded(true);
    }, 200);
  }, []);

  const inputRef = useRef(null);

  useEffect(() => {
    if (showNumericKeypad) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [showNumericKeypad]);

  const handleKeyClick = (keyContent) => {
    if (keyContent === "Clear") {
      setInputValue("");
      setMoneyValue(null);
      setGetCash({ amount: "", charge: "" });
      sessionStorage.removeItem("money");
    } else if (keyContent === "X") {
      setShowNumericKeypad(false);
    } else {
      setInputValue(inputValue + keyContent);
    }
  };

  const handleDateInputClick = () => {
    setShowNumericKeypad(true);
  };

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  const handleSpray = async () => {
    setLoading(true);
    sessionStorage?.setItem("amount", inputValue);

    // setTimeout(() => {
    //   window.location?.assign("/payment");
    //   setLoading(false);
    // }, 2000);

    // uncomment when switching back to up

    const data = JSON.stringify({
      data: {
        secret,
        transactionType: 'card',
        amount: sessionStorage?.getItem("amount", inputValue),
      },
    });

    try {
      const response = await put(baseURL, "/guestapi/payment/", data, headers);

      if (response.status) {
        toast.success("",{
          description: response.message || "Successful",
          style: { color: "green" },
        });
        clearSessionStorageForOrigin('https://media.partytime.ng');
        setLoading(false);
        sessionStorage?.removeItem("guestImg");
        window?.location?.assign(response?.link);
      } else {
        toast.error("Error", {
          description:
            response.message || "Something went wrong, please try again!",
          style: { color: "red" },
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error:", {
        description: error?.message || "Something went wrong, please try again!",
        style: { color: "red" },
      });
    }
  };

  useEffect(() => {
    const storedMoney = sessionStorage.getItem("money");
    setMoneyValue(storedMoney);
  }, [singleValue, moneyValue]);

  const handleMoneyClick = (amount) => {
    sessionStorage.setItem("money", amount?.amount);
    setSingleValue(amount?.amount);
    setInputValue(amount?.amount);
    setShowNumericKeypad(false);
  };

  useEffect(() => {
    if (inputValue !== "") {
      setdisable(false);
    } else {
      setdisable(true);
    }
  }, [inputValue]);

  const endpoint = "/guestapi/payment/";
  const headers = {
    "Content-Type": "application/json",
  };

  const fetchCash = async () => {
    setLoading1(true);
    setdisable(true);
    const data = JSON.stringify({
      data: {
        amount: inputValue,
      },
    });

    try {
      const response = await post(baseURL, endpoint, data, headers);

      if (response.status) {
        setLoading1(false);
        setdisable(false);
        setGetCash({
          amount: response.data.amount,
          charge: response.data.charge,
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading1(false);
      setdisable(true);
      // toast.error(error.message)
      toast.error("Something went wrong! Try again.");
      // console.log(error);
    }
  };

  // const [balanceLoading, setBalanceLoading] = useState(false);
  // const [getBalance, setgetBalance] = useState("");

  const { fetchBalanceWithoutRefundPopUp, balanceRef } = useContext(FetchBalanceContext);

  useEffect(() => {
    Aos.init({
      duration: 200,
      easing: "ease-in-out",
    });
  }, []);

  const prevInputValueRef = useRef(null);

  // const fetchBalance = async () => {
  //   setBalanceLoading(true);

  //   const data = JSON.stringify({
  //     data: {
  //       secret,
  //     },
  //   });
  //   try {
  //     const response = await axios.post(`${baseURL}/guestapi/balance/`, data, {
  //       headers,
  //     });

  //     if (response.data.status) {
  //       setBalanceLoading(false);
  //       setgetBalance(response?.data?.data?.fund?.balance);
  //       sessionStorage.setItem(
  //         "balance",
  //         response?.data?.data?.fund?.totalSprayed
  //       );

  //       setTimeout(() => {
  //         //   setCheckBalanceModal(true);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     setBalanceLoading(false);

  //     toast.error("", {
  //       description: error?.response?.data?.message,
  //       style: { color: "red" },
  //     });
  //   }
  // };

  useEffect(() => {
    fetchBalanceWithoutRefundPopUp();
  }, []);

  useEffect(() => {
    const delayedFetchCash = () => {
      fetchCash();
    };

    // Set the previous input value when inputValue changes
    prevInputValueRef.current = inputValue;

    // Set a timeout to call fetchCash after 2 minutes (120,000 milliseconds)
    const timeoutId = setTimeout(() => {
      // Check if the current inputValue is the same as the value when the timeout was set
      if (inputValue === prevInputValueRef.current && inputValue !== "") {
        delayedFetchCash();
      }
    }, 1000);

    // Cleanup function to clear the timeout when the component unmounts or inputValue changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  sessionStorage?.getItem("fund");

  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  return (
    <>
      {buyFundsPopUp && (
        <StyledModalBackground1
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setBuyFundsPopUp(false);
              setShowNumericKeypad(false);
              sessionStorage?.removeItem("fund");
            }
          }}
        >
          <StyledModalContent
            padding="0 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight={`98vh`}
            width="100%"
            // data-aos="fade-up"
            // data-aos-easing="linear"
            // data-aos-duration="400"
            style={{ height: `cal(100% - ${headerHeight}px)` }}
          >
            <InfoContainer>
              <div className="header">
                <div
                  style={{
                    width: "20%",
                    color: Colors.font3,
                    fontSize: "0.8rem",
                  }}
                ></div>
                <div style={{ width: "60%", margin: "auto" }}>Buy New Note</div>
                <div
                  style={{ width: "20%" }}
                  className="Close"
                  onClick={() => {
                    setBuyFundsPopUp(false);
                    setShowNumericKeypad(false);
                    sessionStorage?.removeItem("fund");
                  }}
                >
                  Close
                </div>
              </div>

              <div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={isLoaded ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1, ease: "easeOut" }}
                  style={{ padding: "1rem" }}
                >
                  <div
                    style={{
                      height: `${showNumericKeypad ? "45vh" : "98vh"}`,
                      overflowX: "auto",
                      width: "100%",
                    }}
                    className="shadow"
                  >
                    {/* <div className="hostoff" style={{ marginBottom: ".6rem" }}>
                      Your remaining balance:{" "}
                      <span
                        style={{
                          color: Colors.PrimaryColor,
                          fontSize: "1.1rem",
                          marginLeft: ".5rem",
                        }}
                      >
                        ₦{" "}
                        {balanceLoading
                          ? "loading..."
                          : `${
                              getBalance === "null"
                                ? ""
                                : formatNumber(parseFloat(getBalance))
                            }.00`}
                      </span>
                    </div> */}
                    <div className="hostoff">
                      Select a bundle which contains 100 notes
                    </div>
                    <section onClick={() => setShowNumericKeypad(false)}>
                      {moneyArr?.map((item) => (
                        <div
                          className="btn1"
                          style={
                            {
                              // backgroundImage: `url(${item?.backG})`,
                              // backgroundPosition: "cover",
                            }
                          }
                          key={item?.id}
                        >
                          <div
                            style={{
                              border:
                                moneyValue === item?.amount
                                  ? `2px solid ${Colors?.PrimaryColor}`
                                  : `2px solid transparent`,
                              // backgroundColor: `white`,
                              backgroundImage: `url(${
                                moneyValue === item?.amount
                                  ? item?.clickBackG
                                  : item?.backG
                              })`,
                              // boxShadow: `3px 0px 5px${Colors.BoxShadow}`,
                              borderRadius: ".3rem",
                              fontSize: ".95rem",
                              padding: ".3rem .5rem",
                              width: "100%",
                              // display: "flex",
                              // justifyContent: "space-around",
                              // alignItems: "center",
                              height: "100px",
                              overflow: "hidden",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              background:
                                moneyValue === item?.amount
                                  ? item?.clickedBackColor
                                  : item?.backColor,

                              color:
                                moneyValue === item?.amount
                                  ? `${Colors?.PrimaryColor}`
                                  : `${Colors?.font1}`,
                              cursor: "pointer",
                            }}
                            onClick={() => handleMoneyClick(item)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  moneyValue === item?.amount && "amountlable"
                                }
                                style={{
                                  color:
                                    moneyValue === item?.amount
                                      ? Colors?.PrimaryColor
                                      : item?.color,
                                  paddingTop: ".4rem",
                                }}
                              >
                                ₦ {item?.label}
                              </div>
                              <div
                                style={{
                                  opacity:
                                    moneyValue === item?.amount ? "1" : "0",
                                }}
                              >
                                <FaCircleCheck />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: ".6rem",
                              }}
                            >
                              <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={isLoaded ? { opacity: 1, y: 0 } : {}}
                                transition={{
                                  duration: 1,
                                  ease: "easeOut",
                                  delay: 0.6,
                                }}
                                // className={
                                //   moneyValue === item?.amount ? "imgbtnani" : `imgbtn`
                                // }
                                className={`imgbtn`}
                              >
                                <img
                                  src={item?.img}
                                  alt={item?.img}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "contain" }}
                                  loading="lazy"
                                />
                              </motion.div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#ccc",
                        marginTop: ".7rem",
                      }}
                    >
                      <div
                        style={{ width: "45%", borderBottom: "1px solid #ccc" }}
                      ></div>

                      <div
                        style={{
                          width: "10%",
                          textAlign: "center",
                          fontSize: ".85rem",
                        }}
                      >
                        or
                      </div>
                      <div
                        style={{ width: "45%", borderBottom: "1px solid #ccc" }}
                      ></div>
                    </div>

                    <div className="label">Enter Amount Manually</div>
                    <div ref={inputRef} style={{ transition: "linear .4s" }}>
                      <div className="input1" onClick={handleDateInputClick}>
                        <DateInput
                          padding=".8rem"
                          width="100%"
                          type="text"
                          fontsize1=".8rem"
                          value={formatNumber(inputValue || 0)}
                          readOnly
                          placeholder="₦10,000"
                        />
                      </div>

                      {/* <div className="calculation">
              <div className="charge display">
                <div>What you requested</div>
                <div>₦{formatNumber(inputValue || 0)}</div>
              </div>
              <div className="charge display">
                <div>Charges</div>
                <div>
                  ₦{loading1 ? "loading.." : formatNumber(getCash.charge || 0)}
                </div>
              </div>
              <div className="total display">
                <div>In Total</div>
                <div>
                  ₦{loading1 ? "loading.." : formatNumber(getCash.amount || 0)}
                </div>
              </div>
            </div> */}

                      {/* <div
                        style={{
                          fontSize: ".8rem",
                          background: Colors?.lightPri,
                          color: Colors?.font3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1rem",
                          padding: ".6rem",
                          border: "1px solid #f7c5a4",
                          borderRadius: ".4rem",
                        }}
                      >
                        <div
                          style={{
                            color: Colors?.PrimaryColor,
                            paddingRight: ".3rem",
                          }}
                        >
                          <BsInfoCircleFill />
                        </div>
                        You will be charged
                        <div
                          style={{
                            color: Colors?.PrimaryColor,
                            padding: "0 .3rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          ₦
                          {loading1 ? (
                            <BtnLoader color={Colors?.PrimaryColor} />
                          ) : (
                            formatNumber(getCash.charge || 0)
                          )}
                        </div>{" "}
                        for this transaction
                      </div> */}

                      {/* <div
                        style={{
                          color: "transparent",
                          width: "100%",
                          borderBottom: "1px solid #ccc",
                          boxShadow: "0px -5px 5px -5px rgba(0, 0, 0, 0.2)", // Adjust values as needed
                        }}
                      >
                        .
                      </div> */}

                      {/* <div className="total display">
                        <div style={{ color: Colors?.font1 }}>Total</div>
                        <div>
                          ₦
                          {loading1
                            ? "loading.."
                            : formatNumber(getCash.amount || 0)}
                        </div>
                      </div> */}

                      <Button
                        color="white"
                        backgroundcolor={
                          disable ||
                          getCash.amount === "" ||
                          loading1 ||
                          parseFloat(getCash.amount) === 0
                            ? "#ffe1cc"
                            : Colors.PrimaryColor
                        }
                        disabled={
                          disable ||
                          getCash.amount === "" ||
                          loading1 ||
                          parseFloat(getCash.amount) === 0
                        }
                        padding=".8rem"
                        width="100%"
                        type="submit"
                        fontsize1=".9rem"
                        radius="2rem"
                        shadow={`3px 0px 5px${Colors.BoxShadow}`}
                        onClick={handleSpray}
                        style={{ marginTop: "1rem", fontWeight: "600" }}
                      >
                        {loading ? (
                          <BtnLoader />
                        ) : (
                          `Buy ₦${formatNumber(inputValue || 0)}`
                        )}
                      </Button>
                    </div>
                  </div>
                </motion.div>
                <div style={{ height: `${showNumericKeypad ? "40vh" : "0"}` }}>
                {showNumericKeypad && (
                  <div
                    className="numeric"
                    style={{ height: '100%' }}
                    data-aos="fade-up"
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, "Clear", 0, "X"].map(
                      (keyContent) => (
                        <button
                          key={keyContent}
                          className={`key ${
                            keyContent === "Clear" || keyContent === "Enter"
                              ? "special"
                              : ""
                          }`}
                          onClick={() => handleKeyClick(keyContent)}
                        >
                          {keyContent === "Clear" ? (
                            <RiDeleteBack2Line />
                          ) : keyContent === "X" ? (
                            <FaAngleDoubleDown />
                          ) : (
                            keyContent
                          )}
                        </button>
                      )
                    )}
                  </div>
                )}
                </div>
              </div>
            </InfoContainer>
          </StyledModalContent>
        </StyledModalBackground1>
      )}
    </>
  );
}

export default BuyFundsPopUp;

const InfoContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 2rem 0 0.3rem 0;
    position: sticky;
    top: 0;
    background-color: white;
  }

  .Close {
    color: #ff6700;
    font-size: 0.9rem;
    cursor: pointer;
  }
  .numeric {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    gap: 8px;
    background-color: #d6d9df;
    padding: 1rem;
  }

  .key {
    font-size: 22px;
    padding: 10px;
    font-weight: 600;
    text-align: center;
    background-color: white;
    border-radius: 0.4rem;
    color: ${Colors.font4};
    border: none;
    outline: none;
    cursor: pointer;
  }

  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  .shadow {
    box-shadow: 1px 11px 33px 0px rgba(255, 255, 255, 0.75) inset;
    -webkit-box-shadow: 1px 11px 33px 0px rgba(255, 255, 255, 0.75) inset;
    -moz-box-shadow: 1px 11px 33px 0px rgba(255, 255, 255, 0.75) inset;
  }

  .charge {
    color: var(--grey-500, #667085);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Rooney Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0.8rem;
  }

  .total {
    margin-top: 1rem;
  }

  .calculation {
    border-radius: 10px;
    background: #f3f4f4;
    padding: 0.8rem;
    margin-top: 1rem;
  }

  .btn1 {
    width: 48%;
    margin-top: 0.8rem;
  }
  .imgbtn {
    width: 85px;
    /* height: 70px; */
    transition: linear 0.3s;
  }

  .imgbtnani {
    width: 40px;
    height: 70px;
    transform: scale(1.4);
    transform: skew(10deg, -10deg);
  }
  /* .amountlable {
    transform: scale(1.1);
  } */
  .clear,
  .enter {
  }

  .key:hover {
    background-color: #ccc;
  }

  .hostoff {
    font-size: 0.85rem;
    color: #727884;
  }

  .label {
    font-weight: 400;
    margin: 0.7rem 0 0.5rem 0;
    font-size: 0.95rem;
    color: #212121;
  }

  .up {
    border-radius: 50%;
    color: white;
    /* width: ; */
    background: rgba(256, 256, 256, 0.5);
    box-shadow: 0px 0px 20px #414141;
    padding: 0.25rem 0.4rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 20px #414141;
    }
  }
`;
