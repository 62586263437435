import { ClipLoader } from "react-spinners";
// import SyncLoader from "react-spinners/SyncLoader";

function BtnLoader({color}) {
  return (
    <div>
      <ClipLoader color={color || 'fff'} size={23} />
      {/* <SyncLoader color="#fff" size={8} /> */}
    </div>
  );
}

export default BtnLoader;
