/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Navbar from "../Reuseable/Navbar";
import date from "../assets/imgs/calender.svg";
import location from "../assets/imgs/location.svg";
import { styled } from "styled-components";
import { Colors } from "../Style/Colours";
import { Button } from "../Reuseable/StyledComponents";
import BtnLoader from "../Reuseable/BtnLoader";
import { post } from "../Request/GetAPI";
import baseURL from "../Request/BaseUrl";
import DateFormet from "../Reuseable/DateFormet";
import DayFormat from "../Reuseable/DayFormat";
import TimeFormat from "../Reuseable/TimeFormat";
import Progrees from "../Reuseable/Progrees";
import { secret } from "../Request/Secret";
import Skeleton from "react-loading-skeleton";

function EventDetails() {
  const [loading, setLoading] = useState({
    page: false,
    button: false,
  });

  const [event, setEvent] = useState({
    img: "",
    hostName: "",
    hostImg: "",
    about: "",
    namee: "",
    locationn: "",
    ceatedAt: "",
    startDate: "",
    startTime: "",
    closeDate: "",
    code: "",
  });

  const endpoint = "/guestapi/event/";
  const headers = {
    "Content-Type": "application/json",
  };

  let data = JSON.stringify({
    data: {
      secret: secret,
    },
  });

  const getEvent = async (e) => {
    setLoading({ ...loading, page: true });

    try {
      const response = await post(baseURL, endpoint, data, headers);

      if (response.status) {
        setLoading({ ...loading, page: false });
        setEvent({
          img: response?.data?.event?.image,
          hostName: response?.data?.event?.hostname,
          hostImg: response?.data?.event?.hostimage,
          about: response?.data?.event?.about,
          namee: response?.data?.event?.name,
          locationn: response?.data?.event?.location,
          ceatedAt: response?.data?.event?.create_at?.slice(0, 10),
          startDate: response?.data?.event?.startDate,
          startTime: response?.data?.event?.startTime,
          closeDate: response?.data?.event?.closedDate?.slice(11, 19),
          code: response?.data?.event?.code,
        });
      }
    } catch (error) {
      setLoading({ ...loading, page: false });
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  const handleSpray = () => {
    sessionStorage?.setItem("name", event?.namee);
    sessionStorage?.setItem("code", event?.code);

    window?.location?.assign("/amount");
  };
  return (
    <Container>
      {loading.page ? (
        <Skeleton width='100%' height='100vh' /> 
        // 'Loading'
      ) : (
        <>
          <Navbar text="Event Details" />

          <Progrees type="2" />
          <section>
            <div className="celeimg">
              <img src={event?.img} alt="celebrate" width="100%" />
            </div>

            <div className="createall">
              <div className="name">{event?.namee?.toLocaleUpperCase()}</div>
              <div className="create">
                Created on: {<DateFormet value={event?.ceatedAt} />}
              </div>
            </div>

            <div className="host">
              <div className="hostimg">
                <img
                  src={event?.hostImg}
                  alt="host"
                  width="100%"
                  style={{ borderradius: ".4rem" }}
                />
              </div>
              <div>
                <div className="hostname">{event?.hostName}</div>
                <div className="hostoff">Host</div>
              </div>
            </div>

            <div className="about">
              <div className="hostname">About Event</div>
              <div className="hostoff">{event?.about}</div>
            </div>

            <div className="date">
              <div className="datename">Date</div>
              <div className="host">
                <div className="hostimg">
                  <img src={date} alt="host" width="100%" />
                </div>
                <div>
                  <div className="hostname">
                    <DateFormet value={event?.startDate} />
                  </div>
                  <div className="hostoff">
                    {<DayFormat value={event?.startDate} />},{" "}
                    {<TimeFormat value={event?.startTime} />} -{" "}
                    {<TimeFormat value={event?.closeDate} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="date">
              <div className="datename">Location</div>
              <div className="host">
                <div className="hostimg">
                  <img src={location} alt="host" width="100%" />
                </div>
                <div>
                  <div className="locationname">{event?.locationn}</div>
                  <div className="locationoff">Show in maps</div>
                </div>
              </div>
            </div>

            <Button
              color="white"
              backgroundcolor={Colors.PrimaryColor}
              padding=".8rem"
              width="100%"
              type="submit"
              fontsize1=".9rem"
              radius="2rem"
              shadow={`3px 0px 5px${Colors.BoxShadow}`}
              // disabled={isSubmitting}

              onClick={handleSpray}
              style={{ marginTop: "2rem", fontWeight: "600" }}
            >
              {loading?.button ? <BtnLoader /> : "Spray host"}
            </Button>
          </section>
        </>
      )}
    </Container>
  );
}

export default EventDetails;

const Container = styled.div`
  section {
    padding: 1rem;
  }

  .celeimg {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    height: 250px;
    overflow: hidden;
  }
  .celeimg img {
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .name {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .create {
    font-size: 0.85rem;
    color: ${Colors.PrimaryColor};
    font-weight: 500;
    padding: 0.3rem 0;
  }

  .host {
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }

  .hostimg {
    width: 75px;
    padding-right: 1rem;
  }

  .hostname {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .hostoff {
    font-size: 0.9rem;
    color: ${Colors.font3};
  }
  .locationname {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
  }

  .locationoff {
    font-size: 0.8rem;
    color: #434fbf;
    font-weight: 500;
  }

  .datename {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1.5rem;
  }
`;
