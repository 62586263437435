import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ModalContextProvider from "./Context/ModalContextProvider";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Toaster } from "sonner";
import CodeContextProvider1 from "./Context/CodeContextProvider1";
import OpenFundsModalsProvider from "./Context/OpenFundsModalsProvider";
import FetchBalanceProvider from "./Context/FetchBalanceProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode> //
  <ModalContextProvider>
    <CodeContextProvider1>
      <Toaster position="top-center" expand={false} />
      <OpenFundsModalsProvider>
        <FetchBalanceProvider>
          <App />
        </FetchBalanceProvider>
      </OpenFundsModalsProvider>
    </CodeContextProvider1>
  </ModalContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
