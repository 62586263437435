import React, { useEffect, useState } from "react";
// import { Colors } from "../Style/Colours";
import {
  Button,
  StyledModalBackground,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import styled, { keyframes } from "styled-components";
import AOS from "aos";

//images
// import hundred1 from "../assets/currencency/hundred.png";
// import hundred2 from "../assets/currencency/two.png";
// import five from "../assets/currencency/five.png";
import thousand from "../assets/currencency/thousand.png";

// bundle2 money
// import bundle2100 from "../assets/live/Bundles2/bundles2100.png";
// import bundle2200 from "../assets/live/Bundles2/bundles2200.png";
// import bundle2500 from "../assets/live/Bundles2/bundles2500.png";
import bundle21000 from "../assets/live/Bundles2/bundles21000.png";

//audios
import Audio from "../assets/Audio/moneyCounter.mp3";
import SuccessSound from "../assets/Audio/moneySuccess.mp3";

import { put } from "../Request/GetAPI";
import baseURL from "../Request/BaseUrl";
import { secret } from "../Request/Secret";
import { toast } from "sonner";
import { Colors } from "../Style/Colours";

function PrintingModal({setPrintModal}) {
  const [printed, setPrinted] = useState(true);
  const [checkIfErrorInRedirection, setCheckIfErrorInRedirection] =
    useState(false);

  const endpoint = "/guestapi/payment/";
  const headers = {
    "Content-Type": "application/json",
  };

  const amount = sessionStorage.getItem("amount");

  const handleSpray = async (retryCount = 0) => {
    setCheckIfErrorInRedirection(false);
    const data = JSON.stringify({
      data: {
        secret,
        transactionType: "card",
        amount,
      },
    });

    try {
      const response = await put(baseURL, endpoint, data, headers);

      if (response.status) {
        toast.success("", {
          description: response.message || "Successful",
          style: { color: "green" },
        });
        setCheckIfErrorInRedirection(false);
        window?.location?.assign(response?.link);
      } else {
        if (retryCount < 3) {
          // Retry the request
          await handleSpray(retryCount + 1);
        } else {
          // If the retry count exceeds 3, set checkIfErrorInRedirection to true
          setCheckIfErrorInRedirection(true);
        }
        console.log(response);
        // throw new Error(response.message);
        toast.error("Error", {
          description: response?.message || "Something went wrong, please try again!",
          style: { color: "red" },
        });
      }
    } catch (error) {
      console.log(error);
      // Handle other errors here
      setCheckIfErrorInRedirection(true);
      toast.error("Error", {
        description: error.message || "Something went wrong, please try again!",
        style: { color: "red" },
      });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-in-out",
    });

    setTimeout(() => {
      setPrinted(false);
      handleSpray();
    }, 4500);
  }, []);

  useEffect(() => {
    // Start or stop the audio based on the value of printed
    const audioElement1 = document.getElementById("audioPlayer1");
    const audioElement2 = document.getElementById("audioPlayer2");

    if (audioElement1 && audioElement2) {
      if (printed) {
        audioElement1.play().catch((error) => console.error(error));
        audioElement2.pause();
      } else {
        audioElement1.pause();
        audioElement2.play().catch((error) => console.error(error));
      }
    }
  }, [printed]);

  return (
    <>
      <StyledModalBackground
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setPrintModal(false);
          }
        }}
      >
        <StyledModalContent
          // maxwidth="80%"
          width="80%"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="200"
          padding="5rem 1.5rem"
          style={{ overflow: "hidden" }}
        >
          <Container>
            {printed ? (
              <aside>
                <div className="anima">
                  <div className="big">
                    <img src={thousand} alt="" width="100%" loading="lazy" />
                    {/* <PreloadImage /> */}
                  </div>
                  <HelloText>
                    <div className="f">
                      <img src={thousand} alt="" width="80px" loading="lazy" />
                      {/* <PreloadImage2 /> */}
                    </div>
                  </HelloText>
                </div>

                <div className="important">Please be patient</div>
                <div className="text">
                  {/* Your new notes are currently being printed */}
                  Your new notes are currently being prepared.
                </div>
              </aside>
            ) : (
              <aside>
                {!checkIfErrorInRedirection ? (
                  <>
                    {" "}
                    <div className="doen">
                      <div className="doen1">
                        <img src={bundle21000} alt="" width="150px" />
                      </div>
                      <div className="doen2">
                        <img src={bundle21000} alt="" width="150px" />
                      </div>
                    </div>
                    <div className="important">Yaay!</div>
                    <div className="text">
                      Your new notes have been successfully prepared. Please
                      continue to print.
                    </div>
                  </>
                ) : (
                  <div>
                    <Button
                      color="white"
                      backgroundcolor={Colors.PrimaryColor}
                      padding=".6rem"
                      width="100%"
                      type="submit"
                      fontsize1="1rem"
                      radius="2rem"
                      shadow={`3px 0px 5px${Colors.BoxShadow}`}
                      // disabled={isSubmitting}

                      onClick={() => handleSpray()}
                      style={{ margin: "3rem 0", fontWeight: "500" }}
                    >
                      {"Continue"}
                    </Button>
                  </div>
                )}
              </aside>
            )}

            <div style={{ display: "none" }}>
              <audio id="audioPlayer1" autoPlay>
                <source src={Audio} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>

              <audio id="audioPlayer2" autoPlay>
                <source src={SuccessSound} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          </Container>
        </StyledModalContent>
      </StyledModalBackground>
    </>
  );
}

export default PrintingModal;

const slideRight = keyframes`
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const HelloText = styled.div`
  width: 100%;

  .f {
    animation: ${slideRight} 1s linear infinite;
  }
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg) scale(1.1);
    
  }
  to {
    transform: rotate(-20deg);
   
  }
`;

const moneyAnimation = keyframes`
  from {
    transform: scale(1.01);
    // transform: translateY(-30%);
  }
  to {
    transform: scale(1.1);
    // transform: translateY(50%);
  }
`;

const Container = styled.div`
  .important {
    color: var(--Text, #212121);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 1rem;
  }

  .text {
    color: #626060;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .anima {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 2.5rem;
  }

  .big {
    background: white;
    z-index: 2;
    animation: ${spinAnimation} 1s linear infinite; // Rotate continuously over 2 seconds
  }

  .doen {
    // text-align: center;
    position: relative;
    height: 120px;
    width: 100%;
  }

  .doen2 {
    position: absolute;
    left: 30%;
    top: 10%;
    transform: translate(-50%, -50%);
    animation: ${moneyAnimation} 1s linear infinite; // Rotate continuously over 2 seconds
  }
  .doen1 {
    position: absolute;
    left: 15%;
    top: 15%;
    transform: translate(-50%, -50%);
    animation: ${moneyAnimation} 1s linear infinite; // Rotate continuously over 2 seconds
  }
`;
