// api.js
import axios from 'axios';

const getWithParam = async (baseURL, endpoint, params, headers) => {
  try {
    const response = await axios.get(`${baseURL}${endpoint}${params}`, {
      
      headers, // Include the headers in the request
    });
    return response.data;
  } catch (error) {
    // Handle errors, e.g., log or throw an error
    throw new Error('Failed to fetch data from the server.');
  }
};

const get = async (baseURL, endpoint, params, headers) => {
  try {
    const response = await axios.get(`${baseURL}${endpoint}`, {
      params,
      headers, // Include the headers in the request
    });
    return response.data;
  } catch (error) {
    // Handle errors, e.g., log or throw an error
    throw new Error('Failed to fetch data from the server.');
  }
};

const post = async (baseURL, endpoint, data, headers) => {
  try {
    const response = await axios.post(`${baseURL}${endpoint}`, data, {
      headers, // Include the headers in the request
    });
    return response.data;
  } catch (error) {
    // Handle errors, e.g., log or throw an error
    throw new Error('Failed to create data on the server.');
  }
};
const put = async (baseURL, endpoint, data, headers) => {
  try {
    const response = await axios.put(`${baseURL}${endpoint}`, data, {
      headers, // Include the headers in the request
    });
    return response.data;
  } catch (error) {
    // Handle errors, e.g., log or throw an error
    throw new Error('Failed to create data on the server.');
  }
};

export { get, post, getWithParam, put };
