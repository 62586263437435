import React from "react";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import { StyledModalContent } from "../Reuseable/StyledComponents";
import Confetti from "react-confetti";

function ThankYouMessage({ message }) {
  const details = JSON.parse(sessionStorage?.getItem("details"));
  const { width: screenWidth, height: screenHeight } = useWindowSize();

  return (
    <StyledModalContent
      width="100%"
      maxHeight780='100%'
      data-aos="zoom-in"
      data-aos-easing="linear"
      data-aos-duration="400"
    >
      <Container>
        <Confetti width={screenWidth} height={screenHeight} />
        <div className="Thank">
          <div></div>
          <div className="gradient-text">
            {/* <q> */}
              {" "}
              {
              (message && message) ||
                "Thank You For Making Our Party Unforgettable. Your Presence Added So Much Joy To The Celebration!"}{" "}
            {/* </q> */}
          </div>
        </div>

        <div className="Big">
          {" "}
          ~ {(details && details?.hostName) || "Big Love"}
        </div>
      </Container>
    </StyledModalContent>
  );
}

export default ThankYouMessage;

const Container = styled.div`
  font-weight: 800;
  /* text-transform: uppercase; */
  height: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  .Big {
    color: #ff6700;
    font-size: 1.2rem;
    display: flex;
    justify-content: end;
  }

  .Thank {
   
    text-align: center;
    /* padding: 1rem 1rem 1rem 1rem; */
    margin-bottom: 4rem;
    font-size: 1.1rem;
    background-color: rgba(256, 256, 256, 0.8);
    height: 100%;
    z-index: 200;
    border-radius: 0.4rem;
  }

  .gradient-text {
    font-size: 2.2rem;
    font-weight: 800;
    background-image: linear-gradient(to right, #ff6700, #007aff);
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    color: transparent;
    font-family: boldFont;
    z-index: 999;
    padding: 6rem 0 3rem 0;
    animation: animateGradient 5s linear infinite;
    display: inline-block; /* Ensure the animation works correctly */
}

@keyframes animateGradient {
    0% {
        background-position: 0% 50%;
        background-image: linear-gradient(to right, #007aff #ff6700,);
    }
    100% {
        background-position: 100% 50%;
        background-image: linear-gradient(to right, #ff6700, #007aff);
    }
}


  @media (min-width: 800px) {
    .Thank {
      padding: 2rem 1rem 2rem 1rem;
      font-size: 1.3rem;
      margin-bottom: 6rem;
    }
  }
`;
