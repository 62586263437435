import React from 'react';

const DateFormet = ({ value }) => {
  const formatDate = (date) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('default', { month: 'long' });
    const year = dateObject.getFullYear();
    return `${day}, ${month}, ${year}`;
  };

  const formattedDate = formatDate(value);

  return (
    <>{formattedDate}</>
  );
};

export default DateFormet;