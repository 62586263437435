/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Success from "../assets/imgs/Success.gif";
import failed from "../assets/imgs/failed.gif";
import { Button } from "../Reuseable/StyledComponents";
import { Colors } from "../Style/Colours";
// import { post } from "../Request/GetAPI";
// import baseURL from "../Request/BaseUrl";
// import { secret } from "../Request/Secret";
// import Skeleton from "react-loading-skeleton";
import PageLoader from "../Reuseable/PageLoader";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

function SuccessfulTransaction() {
  const [loader, setLoader] = useState(false);
  // const header = useRef("");
  const [status, setStatus] = useState(null);

  // const endpoint = "/guestapi/cardredirect/";
  // const headers = {
  //   "Content-Type": "application/json",
  // };

  const { width: screenWidth, height: screenHeight } = useWindowSize();
  // const handleSpray = async (trxref, reference) => {
  //   setLoader(true);
  //   const data = JSON.stringify({
  //     trxref: trxref,
  //     reference: reference,
  //     secret,
  //   });

  //   try {
  //     const response = await post(baseURL, endpoint, data, headers);

  //     if (response.status) {
  //       setLoader(false);
  //       header.current = response?.message;
  //       setStatus(response?.status);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  const handleCantinue = () => {
    // localStorage?.removeItem("a1mount2");
    window?.location?.assign("/live");
  };

  const handleTry = () => {
    window?.location?.assign("/amount");
  };

  // /successtransaction
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const statuss = params.get("success");
    // const reference = params.get("reference");
    setStatus(statuss && statuss);

    // const fetchData = async () => {
    //   try {
    //     // Make a POST request
    //     const response = await fetch('http://localhost:3000/successtransaction', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         // Add any additional headers if needed
    //       },
    //       body: JSON.stringify({
    //         // Include any data you need to send in the request body
    //       }),
    //     });

    //     // Check if the request was successful (status code 2xx)
    //     if (response.ok) {
    //       // Redirect logic on the client side
    //       // window.location.href = 'https://example.com';
    //       console.log(response);
    //     } else {
    //       console.error('Error:', response.statusText);
    //     }
    //   } catch (error) {
    //     console.error('Error:', error.message);
    //   }
    // };

    // // Call the fetch function
    // fetchData();

    // handleSpray(trxref, reference);
  }, []);

  return (
    <Container>
      {loader && <PageLoader />}
      <>
        {status === "true" && (
          <Confetti width={screenWidth} height={screenHeight} />
        )}
        <div className="status">
          {/* <ConfettiExplosion /> */}

          {status === "true" && (
            <div style={{ width: "250px" }} className="ballons">
              <img
                src={Success}
                width="100%"
                alt=""
                style={{ mixBlendMode: "color-burn11" }}
              />
            </div>

            // <div className="ballons">
            //   <div style={{ width: "100%" }}>
            //     {" "}
            //     <img src={ballons} width="100%" alt="" />
            //   </div>
            //   <div style={{ width: "100%" }}>
            //     <img src={Success} width="100%" alt="" />
            //   </div>
            //   <div style={{ width: "100%" }}>
            //     <img src={ballons} width="100%" alt="" />
            //   </div>
            // </div>
          )}
          {status === "false" && (
            <div style={{ width: "250px" }} className="ballons">
              <img
                src={failed}
                width="100%"
                alt=""
                style={{ mixBlendMode: "color-burn11" }}
              />
            </div>
          )}
        </div>
        <div className="textbtn">
          <div className="Transaction">
            {/* {header.current && header.current.toUpperCase()} */}
            {status === "true" && "SUCCESSFUL FUNDING"}
            {status === "false" && "FUNDING FAILED"}
          </div>
          <div className="request">
            {status === "true" &&
              "Your request has been processed successfully."}
            {status === "false" &&
              " There might be some issues with your payment option"}
          </div>
          {status === "true" && (
            <Button
              color={Colors?.font1}
              backgroundcolor="white"
              padding=".8rem"
              width="100%"
              type="submit"
              fontsize1=".95rem"
              radius="2rem"
              border={`2px solid #ccc`}
              shad="none"
              style={{ fontWeight: "600" }}
              onClick={handleCantinue}
              className="btn23"
            >
              Start Spraying
            </Button>
          )}
          {status === "false" && (
            <Button
              color={Colors?.font1}
              backgroundcolor="white"
              padding=".8rem"
              width="100%"
              type="submit"
              fontsize1=".95rem"
              radius="2rem"
              border={`2px solid #ccc`}
              shad="none"
              style={{ fontWeight: "600" }}
              onClick={handleTry}
            >
              Try again
            </Button>
          )}
        </div>
      </>
    </Container>
  );
}

export default SuccessfulTransaction;

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;

  .Transaction {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 0;
  }

  .ballons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .request {
    font-size: 1rem;
    color: ${Colors.font3};
    margin-bottom: 3rem;
    text-align: center;
  }

  .status {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .textbtn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  @media screen and (min-width: 720px) {
    .textbtn {
      width: 70%;
    }
  }
`;
