import React from "react";

function DayFormat({ value }) {
  const formatDay = (date) => {
    const dayName = new Date(date).toLocaleString("default", { weekday: "long" });
    return dayName;
  };

  return <>{formatDay(value)}</>;
}

export default DayFormat;
