import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { Colors } from "../Style/Colours";
import {
  Button,
  StyledModalBackground1,
  StyledModalContent,
} from "../Reuseable/StyledComponents";
import { secret } from "../Request/Secret";
import coins from "../assets/icons/coins.png";
import Aos from "aos";

import { GoX } from "react-icons/go";
import Notification from "../assets/Audio/notificationSound.mp3";
import AlertNotification from "../assets/Audio/alertNoti.mp3";
import { LiaTimesSolid } from "react-icons/lia";
import BtnLoader from "../Reuseable/BtnLoader";
import axios from "axios";
import { OpenFundsModal } from "../Context/OpenFundsModalsProvider";
import Modal from "../Modals/Modal";
import baseURL from "../Request/BaseUrl";
import { FetchBalanceContext } from "../Context/FetchBalanceProvider";
import useWebSocket from "react-use-websocket";
import canAutoplay from 'can-autoplay';

function RequestMessagesSocket({ item }) {

  const playNotificationSound = async (audio) => {
    try {
      // Check if the browser can autoplay audio
      const { result } = await canAutoplay.audio();
  
      if (result) {
        // Create a new audio player and play the audio if autoplay is supported
        const audioPlayer = new Audio(audio);
        audioPlayer.play();
      } else {
        console.log("Autoplay is not supported for audio in this browser.");
        // You can add a fallback mechanism here if needed
      }
    } catch (error) {
      console.error("Error checking autoplay support:", error);
    }
  };

  const [isDisabled, setisDisabled] = useState(true);
  const [getAmountValue, setgetAmountValue] = useState("");
  const [getDataValue, setgetDataValue] = useState("");
  const [getReguestText, setGetReguestText] = useState("");

  const {
    acceptRequestFund,
    setAcceptRequestFund,
    shareModal,
    setShareModal,
    showBubblePopUpWhenSprayedOtherGuest,
    setShowBubblePopUpWhenSprayedOtherGuest,
  } = useContext(OpenFundsModal);

  const [loading, setLoading] = useState(false);
  const {
    fetchBalance,
    loading: contextLoading,
    setLoading: setContextLoading,
  } = useContext(FetchBalanceContext);

  const formatNumber = (value) => {
    return parseFloat(value).toLocaleString("en-US");
  };

  useEffect(() => {
    if (getAmountValue) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }, [getAmountValue]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);

  // const getCode = JSON.parse(sessionStorage.getItem("details"));

  const requestData = {
    id: getDataValue.id,
    amount: Number(getAmountValue),
    reciever: getDataValue.reciever,
    secret,
    response: true,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guestapi/acceptrequest/`,
        requestData,
        { headers }
      );

      if (response.data.status) {
        setLoading(false);

        toast.success("", {
          description: response.data?.message || "Sent",
          style: { color: "green" },
        });

        setAcceptRequestFund(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("", {
        description:
          error?.response?.data?.message || "Opps!! something went wrong",
        style: { color: "red" },
      });
    }
  };

  const totalHeight = window.outerHeight;

  // Get the viewport height
  const viewportHeight = window.innerHeight;

  // Calculate the header height
  const headerHeight = totalHeight - viewportHeight;

  const socketUrl = `wss://backend.partytime.ng/ws/notification/${secret}/`;

  const {
    lastJsonMessage,
  } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    let interval;
    if (showBubblePopUpWhenSprayedOtherGuest) {
      interval = setInterval(() => {
        setShowBubblePopUpWhenSprayedOtherGuest(false);
        clearInterval(interval);
      }, 5000);
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [showBubblePopUpWhenSprayedOtherGuest]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    setGetReguestText(lastJsonMessage.message);
    setContextLoading(true);

    if (lastJsonMessage.info === "request") {
      const amount = lastJsonMessage?.message?.data?.amount;
      const data = lastJsonMessage?.message?.data;
      setgetAmountValue(amount);
      setgetDataValue(data);
      // setAcceptRequestFund(true);

      // Play the audio
      // const audioPlayer = new Audio(Notification);
      // audioPlayer.play();
      playNotificationSound(Notification)
      fetchBalance()

      toast.custom(
        (t) => (
          <ToastContainer>
            <div style={{ display: "flex" }}>
              {lastJsonMessage?.message?.message?.includes("#")
                ? lastJsonMessage.message.message.replace(/#/g, "₦")
                : lastJsonMessage?.message?.message || "No message available"}
            </div>
            <div className="display" style={{ paddingTop: "1.5rem" }}>
              <Button
                color={Colors.font1}
                padding=".5rem 0"
                width="48%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                border={`1px solid ${Colors.font1}`}
                shadow={`3px 0px 5px ${Colors.BoxShadow}`}
                style={{ fontWeight: "600" }}
                onClick={() => toast.dismiss(t)}
              >
                Ignore
              </Button>
              <Button
                color="white"
                backgroundcolor={Colors.PrimaryColor}
                padding=".6rem 0"
                width="48%"
                type="submit"
                fontsize1=".9rem"
                radius="2rem"
                style={{ fontWeight: "600" }}
                onClick={() => {
                  setAcceptRequestFund(true);
                  setShareModal(false);
                  toast.dismiss(t);
                }}
              >
                Accept
              </Button>
            </div>
          </ToastContainer>
        ),
        { duration: 10000 }
      );
    }

    if (lastJsonMessage.info === "alert") {
      // Play the audio
      // const audioPlayer = new Audio(AlertNotification);
      // audioPlayer.play();
      playNotificationSound(AlertNotification);
      fetchBalance();

      // Show the toast with the alert message
      toast.custom(
        (t) => (
          <AlertMessage>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: "20%" }}>
                <img src={coins} width={"100%"} alt="" />
              </span>
              {lastJsonMessage.message
                ? lastJsonMessage.message.includes("#")
                  ? lastJsonMessage.message.replace(/#/g, "₦")
                  : lastJsonMessage.message
                : "No message available"}
            </div>
            <Button
              color={Colors.font1}
              padding=".5rem 0"
              type="submit"
              fontsize1=".9rem"
              onClick={() => toast.dismiss(t)}
            >
              <GoX size={25} />
            </Button>
          </AlertMessage>
        ),
        { duration: 10000 }
      );
    }

    if (lastJsonMessage.info === "spray") {
      // Play the audio
      // const audioPlayer = new Audio(AlertNotification);
      // audioPlayer.play();

      playNotificationSound(AlertNotification);
      setShowBubblePopUpWhenSprayedOtherGuest(true);

      // Show the toast with the alert message
      toast.custom(
        (t) => (
          <AlertMessage>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: "15%" }}>
                <img src={coins} width={"80%"} alt="" />
              </span>
              {lastJsonMessage?.message?.includes("#")
                ? lastJsonMessage.message.replace(/#/g, "₦")
                : lastJsonMessage?.message || "No message available"}
            </div>
            <Button
              color={Colors.font1}
              padding=".3rem 0"
              type="submit"
              fontsize1=".9rem"
              onClick={() => toast.dismiss(t)}
            >
              <GoX size={25} />
            </Button>
          </AlertMessage>
        ),
        { duration: 6000 }
      );
    }
  }, [lastJsonMessage]);


  return (
    <div>
      <Modal open={acceptRequestFund}>
        <StyledModalBackground1
          height={`cal(100vh - ${headerHeight}px)`}
          style={{ zIndex: "100" }}
        >
          <StyledModalContent
            padding="1rem 0 0 0"
            radius="1rem 1rem 0 0"
            MinHeight="85vh"
            width="100%"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="400"
          >
            <Container>
              <div className="header">
                <div
                  style={{
                    width: "20%",
                    color: Colors.font3,
                    fontSize: "0.8rem",
                  }}
                ></div>
                <div
                  style={{
                    width: "60%",
                    fontWeight: "600",
                    margin: "auto",
                  }}
                >
                  Share funds
                </div>
                <div
                  style={{ width: "20%" }}
                  className="Close"
                  onClick={() => setAcceptRequestFund(false)}
                >
                  <LiaTimesSolid size={30} />
                </div>
              </div>

              <div className="bodyDiv">
                <div>
                  {/* <label htmlFor="name">Name</label> */}
                  {/* <div>{getReguestText?.message}</div> */}
                  <div>
                    {" "}
                    {getReguestText.message?.includes("#")
                      ? getReguestText.message.replace(/#/g, "₦")
                      : getReguestText?.message}
                  </div>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <label htmlFor="name">How much?</label>
                  <input
                    type="text"
                    defaultValue={getAmountValue}
                    onChange={(e) => setgetAmountValue(e?.target?.value)}
                    placeholder={`enter amount`}
                  />
                </div>

                <Button
                  color="white"
                  backgroundcolor={Colors.PrimaryColor}
                  // disabled={
                  //   isDisabled ||
                  //   getAmountValue === "" ||
                  //   parseFloat(getAmountValue) === 0
                  // }
                  padding=".8rem"
                  width="100%"
                  type="submit"
                  fontsize1=".9rem"
                  radius="2rem"
                  onClick={handleSubmit}
                  style={{ margin: "3rem 0 0 0", fontWeight: "600" }}
                >
                  {loading ? (
                    <BtnLoader />
                  ) : (
                    `Send ₦ ${formatNumber(getAmountValue || 0)}`
                  )}
                </Button>
              </div>
            </Container>
          </StyledModalContent>
        </StyledModalBackground1>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default RequestMessagesSocket;

const ToastContainer = styled.div`
  background-color: white;
  text-align: center;
  border-radius: 0.4rem;
  padding: 1rem 0.5rem;
  width: 100%;

  @media (min-width: 800px) {
    width: 200%; /* Adjust the width for larger screens */
  }
`;

const AlertMessage = styled.div`
  background-color: white;
  border-radius: 0.4rem;
  padding: 0.5rem;
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media (min-width: 800px) {
    width: 200%; /* Adjust the width for larger screens */
  }
`;

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 1.6rem 0 0.3rem 0;
  }

  .Close {
    color: ${Colors.font3};
    font-size: 0.9rem;
    cursor: pointer;
  }

  .bodyDiv {
    padding: 1rem;
  }

  .bodyDiv input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 0.8rem;
    border-radius: 0.3rem;
    outline: none;
    font-size: 1rem;
    margin: 0.3rem 0 1rem 0;

    &:read-only {
      background-color: #e8e8e8;
      color: #4a4a4a;
      cursor: not-allowed;
      border: none;
    }
    &::placeholder {
      color: #b6b6b6;
      font-size: 0.9rem;
    }
  }
  .bodyDiv label {
    font-size: 0.95rem;
    font-weight: 600;
    color: #4a4a4a;
  }
`;
